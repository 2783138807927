import React, { Component } from "react";
import AUX from "../../hoc/Aux_";
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert/lib/dist/SweetAlert";
import { getMovie, updateMovie } from "../../store/actions/movieActions";
import { getCategories } from "../../store/actions/categoryActions";
import { connect } from "react-redux";
import Select from "react-select";

class MovieEdit extends Component {
  state = {
    alert: null,
    isLoading: false,
    categories: [],
    id: "",
    name: "",
    description: "",
    path: "",
    coverSM: "",
    coverMD: "",
    newPath: "",
    newCoverSM: "",
    newCoverMD: "",
    onSlider: "",
    type: "",
    selectedCategories: null,
    errors: {
      name: "",
      description: "",
      path: "",
      coverSM: "",
      coverMD: "",
      newPath: "",
      newCoverSM: "",
      newCoverMD: "",
      onSlider: "",
      type: "",
      selectedCategories: "",
    },
    isSubmit: false,
  };
  componentDidMount() {
    this.setState({
      isLoading: true,
    });
    this.props
      .getMovie({ movieId: this.props.match.params.movieId })
      .then(() => {
        console.log(this.props.movie);
        this.props.getCategories().then(() => {
          let cats = this.props.categories.map((category) => {
            return {
              value: category.id,
              label: category.name,
            };
          });
          let selectedCats =
            this.props.movie.categories.length > 0
              ? this.props.movie.categories.map((category) => {
                  return {
                    value: category.id,
                    label: category.name,
                  };
                })
              : null;
          this.setState({
            isLoading: false,
            id: this.props.movie.id,
            name: this.props.movie.name,
            description: this.props.movie.description,
            path: this.props.movie.path,
            coverSM: this.props.movie.coverSM
              ? "https://itv-lm.paraclet-bk.com/storage/" +
                this.props.movie.coverSM
              : null,
            coverMD: this.props.movie.coverMD
              ? "https://itv-lm.paraclet-bk.com/storage/" +
                this.props.movie.coverMD
              : null,
            onSlider: this.props.movie.onSlider === "1" ? true : false,
            type: this.props.movie.type,
            selectedCategories: selectedCats,
            categories: cats,
          });
        });
      })
      .catch(() =>
        this.setState({
          isLoading: false,
        })
      );
  }
  validator(name, value, type, files, checked) {
    if (type === "file") {
      let errors = this.state.errors;
      switch (name) {
        case "newCoverSM":
          if (files.length > 0) {
            errors.newCoverSM =
              files[0].size > 300000
                ? "Le poids de l'image de couverture doit être inférieur à 300Ko."
                : "";
          } else {
            // errors.cover = "Veuillez télécharger l'image de couverture svp.";
            errors.newCoverSM = "";
          }
          break;
        case "newCoverMD":
          if (files.length > 0) {
            errors.newCoverMD =
              files[0].size > 300000
                ? "Le poids de l'image de couverture doit être inférieur à 300Ko."
                : "";
          } else {
            // errors.cover = "Veuillez télécharger l'image de couverture svp.";
            errors.newCoverMD = "";
          }
          break;
        case "newPath":
          if (files.length > 0) {
            errors.newPath =
              files[0].type !== "video/mp4"
                ? "Le format de movie accepté est le MP4."
                : "";
          } else {
            // errors.movie = "Veuillez télécharger la movie svp.";
            errors.newPath = "";
          }
          break;
        default:
          break;
      }
    } else {
      let errors = this.state.errors;
      switch (name) {
        case "name":
          errors.name =
            value.length < 5
              ? "Le titre doit contenir 5 caractères minimum !"
              : "";
          break;
        case "description":
          errors.description =
            value.length < 10
              ? "La description doit contenir 10 caractères minimum !"
              : "";
          break;
        case "onSlider":
          errors.onSlider =
            checked !== true && checked !== false
              ? "Vérifiez avoir bien rempli ce champ svp !"
              : "";
          break;
        case "type":
          errors.type =
            value.length !== 5
              ? "Vérifiez avoir bien rempli ce champ svp !"
              : "";
          break;
        default:
          break;
      }
    }
  }
  handleInputChange = (e) => {
    const { name, value, type, files, checked } = e.target;
    let errors = this.state.errors;
    if (type === "file") {
      this.validator(name, value, type, files);
      this.setState({ errors, [name]: files[0] }, () => {
        console.log(errors);
      });
    } else if (type === "checkbox") {
      this.validator(name, value, type, files, checked);
      this.setState({ errors, [name]: checked }, () => {
        // console.log(errors);
      });
    } else {
      this.validator(name, value, type, files);
      this.setState({ errors, [name]: value }, () => {
        console.log(errors);
      });
    }
  };
  handleSelectChange = (selectedOption) => {
    let errors = this.state.errors;
    errors.selectedCategories =
      selectedOption === null
        ? "Choisissez au moins une catégorie de vidéo !"
        : "";
    this.setState({ selectedCategories: selectedOption });
  };
  handleSweetAlert(type) {
    let movieId = this.props.match.params.movieId;
    const successAlert = () => (
      <SweetAlert
        success
        title=""
        onConfirm={() => this.props.history.push("/details-movie/" + movieId)}
      >
        Information enregistrée avec succès !
      </SweetAlert>
    );
    const errorAlert = () => (
      <SweetAlert error title="" onConfirm={() => this.hideAlert()}>
        Oups, une erreur a été détecté.
      </SweetAlert>
    );
    this.setState({
      alert: type === "success" ? successAlert() : errorAlert(),
    });
  }
  hideAlert() {
    this.setState({ alert: null });
  }
  handleSubmit = () => {
    this.setState({
      isLoading: true,
      isSubmit: true,
    });
    const {
      id,
      name,
      description,
      path,
      coverSM,
      coverMD,
      onSlider,
      type,
      newCoverSM,
      newCoverMD,
      newPath,
      selectedCategories,
    } = this.state;
    let selectedCats = "";
    selectedCategories.forEach((element, key) => {
      if (key === 0) {
        selectedCats += element.value;
      } else {
        selectedCats += "||" + element.value;
      }
    });
    const movieForm = new FormData();
    movieForm.append("id", id);
    movieForm.append("name", name);
    movieForm.append("description", description);
    movieForm.append("path", newPath);
    movieForm.append("coverSM", newCoverSM);
    movieForm.append("coverMD", newCoverMD);
    movieForm.append("onSlider", onSlider === true ? 1 : 0);
    movieForm.append("type", type);
    movieForm.append("categories", selectedCats);
    let movieId = this.props.match.params.movieId;
    this.props
      .updateMovie(movieForm, movieId)
      .then(() => {
        this.setState({
          isLoading: false,
          isSubmit: false,
        });
        this.handleSweetAlert("success");
      })
      .catch(() => {
        this.setState({
          isLoading: false,
          isSubmit: false,
        });
        this.handleSweetAlert("error");
      });
  };
  render() {
    const {
      id,
      name,
      description,
      path,
      coverSM,
      coverMD,
      onSlider,
      type,
      newPath,
      newCoverSM,
      newCoverMD,
      selectedCategories,
      categories,
      errors,
      alert,
      isLoading,
      isSubmit,
    } = this.state;
    return (
      <AUX>
        {alert}
        <div className="row">
          <div className="col-sm-12">
            <div className="page-title-box">
              <h4 className="page-title">Modification vidéo</h4>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Tableau de bord</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/movies">Vidéos</Link>
                </li>
                <li className="breadcrumb-item active">Modification</li>
              </ol>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card m-b-20">
              <div className="card-body">
                {isLoading ? (
                  <div>
                    <div className="alert alert-info text-center">
                      Chargement en cours ...
                    </div>
                    {isSubmit && (
                      <div className="progress" style={{ height: "20px" }}>
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: this.props.uploadPercent + "%" }}
                          aria-valuenow={this.props.uploadPercent}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          {this.props.uploadPercent + "%"}
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <form>
                    <div className="row">
                      <div className="col-md-8">
                        <div className="form-group">
                          <label>Titre</label>
                          <input
                            type="text"
                            name="name"
                            className={
                              errors.name.length > 0
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            value={name}
                            onChange={this.handleInputChange}
                            noValidate
                          />
                          {errors.name.length > 0 && (
                            <span className="invalid-feedback">
                              {errors.name}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          {coverSM !== "" ? (
                            <div>
                              <button
                                className="btn btn-link"
                                type="button"
                                data-toggle="modal"
                                data-target="#coverModal"
                                style={{ paddingLeft: 0 }}
                              >
                                Image (Image - Petit Format)
                              </button>
                              <div
                                className="modal fade"
                                id="coverModal"
                                tabIndex="-1"
                                role="dialog"
                                aria-labelledby="coverModalLabel"
                                aria-hidden="true"
                              >
                                <div className="modal-dialog" role="document">
                                  <div className="modal-content">
                                    <div className="modal-header">
                                      <h5
                                        className="modal-title"
                                        id="coverModalLabel"
                                      >
                                        Image (Image - Petit Format)
                                      </h5>
                                      <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                      >
                                        <span aria-hidden="true">&times;</span>
                                      </button>
                                    </div>
                                    <div className="modal-body">
                                      <img
                                        src={coverSM}
                                        style={{
                                          width: "100%",
                                          height: "auto",
                                        }}
                                      />
                                    </div>
                                    <div className="modal-footer">
                                      <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-dismiss="modal"
                                      >
                                        FERMER
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <label>Image (Image - Petit Format)</label>
                          )}
                          <input
                            name="newCoverSM"
                            id="newCoverSM"
                            type="file"
                            className={
                              errors.newCoverSM.length > 0
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            onChange={this.handleInputChange}
                            noValidate
                          />
                          {errors.newCoverSM.length > 0 && (
                            <span className="invalid-feedback">
                              {errors.newCoverSM}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          {coverMD !== "" ? (
                            <div>
                              <button
                                className="btn btn-link"
                                type="button"
                                data-toggle="modal"
                                data-target="#coverModalMD"
                                style={{ paddingLeft: 0 }}
                              >
                                Bannière (Image - Format Moyen)
                              </button>
                              <div
                                className="modal fade"
                                id="coverModalMD"
                                tabIndex="-1"
                                role="dialog"
                                aria-labelledby="coverModalLabelMD"
                                aria-hidden="true"
                              >
                                <div className="modal-dialog" role="document">
                                  <div className="modal-content">
                                    <div className="modal-header">
                                      <h5
                                        className="modal-title"
                                        id="coverModalLabel"
                                      >
                                        Bannière (Image - Petit Format)
                                      </h5>
                                      <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                      >
                                        <span aria-hidden="true">&times;</span>
                                      </button>
                                    </div>
                                    <div className="modal-body">
                                      <img
                                        src={coverMD}
                                        style={{
                                          width: "100%",
                                          height: "auto",
                                        }}
                                      />
                                    </div>
                                    <div className="modal-footer">
                                      <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-dismiss="modal"
                                      >
                                        FERMER
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <label>Bannière (Image - Petit Format)</label>
                          )}
                          <input
                            name="newCoverMD"
                            id="newCoverMD"
                            type="file"
                            className={
                              errors.newCoverMD.length > 0
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            onChange={this.handleInputChange}
                            noValidate
                          />
                          {errors.newCoverMD.length > 0 && (
                            <span className="invalid-feedback">
                              {errors.newCoverMD}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <label>Ajouter au slider ?</label>
                          <br />
                          <input
                            type="checkbox"
                            name="onSlider"
                            id="onSlider"
                            switch="none"
                            className={
                              errors.onSlider.length > 0
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            checked={onSlider}
                            onChange={this.handleInputChange}
                            noValidate
                          />
                          <label
                            htmlFor="onSlider"
                            data-on-label="Oui"
                            data-off-label="Non"
                          ></label>
                          {errors.onSlider.length > 0 && (
                            <span className="invalid-feedback">
                              {errors.onSlider}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <label>Type de vidéo</label>
                          <br />
                          <select
                            name="type"
                            id="type"
                            className={
                              errors.type.length > 0
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            onChange={this.handleInputChange}
                            value={type}
                            noValidate
                          >
                            <option value="">--</option>
                            <option value="MOVIE">FILM</option>
                            <option value="SERIE">SERIE</option>
                          </select>
                          {errors.type.length > 0 && (
                            <span className="invalid-feedback">
                              {errors.type}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Description</label>
                          <textarea
                            name="description"
                            className={
                              errors.description.length > 0
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            cols="30"
                            rows="10"
                            value={description}
                            onChange={this.handleInputChange}
                            noValidate
                          ></textarea>
                          {errors.description.length > 0 && (
                            <span className="invalid-feedback">
                              {errors.description}
                            </span>
                          )}
                        </div>
                      </div>
                      {type === "MOVIE" ? (
                        <div className="col-md-12">
                          <div className="form-group">
                            {path !== "" ? (
                              <div>
                                <button
                                  className="btn btn-link"
                                  type="button"
                                  data-toggle="modal"
                                  data-target="#pathModal"
                                  style={{ paddingLeft: 0 }}
                                >
                                  Film
                                </button>
                                <div
                                  className="modal fade"
                                  id="pathModal"
                                  tabIndex="-1"
                                  role="dialog"
                                  aria-labelledby="pathModalLabel"
                                  aria-hidden="true"
                                >
                                  <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                      <div className="modal-header">
                                        <h5
                                          className="modal-title"
                                          id="pathModalLabel"
                                        >
                                          Movie
                                        </h5>
                                        <button
                                          type="button"
                                          className="close"
                                          data-dismiss="modal"
                                          aria-label="Close"
                                        >
                                          <span aria-hidden="true">
                                            &times;
                                          </span>
                                        </button>
                                      </div>
                                      <div className="modal-body">
                                        <movie
                                          controls
                                          width="100%"
                                          height="auto"
                                          controlsList="nodownload"
                                        >
                                          <source src={path} type="video/mp4" />
                                        </movie>
                                      </div>
                                      <div className="modal-footer">
                                        <button
                                          type="button"
                                          className="btn btn-secondary"
                                          data-dismiss="modal"
                                        >
                                          FERMER
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <label>Film</label>
                            )}
                            <input
                              name="newPath"
                              id="newPath"
                              type="file"
                              className={
                                errors.newPath.length > 0
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              onChange={this.handleInputChange}
                              noValidate
                            />
                            {errors.newPath.length > 0 && (
                              <span className="invalid-feedback">
                                {errors.newPath}
                              </span>
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="">Catégories</label>
                          <Select
                            name="selectedCategories"
                            placeholder="--"
                            value={selectedCategories}
                            onChange={this.handleSelectChange}
                            options={categories}
                            isClearable={true}
                            isMulti={true}
                          />
                          {errors.selectedCategories.length > 0 && (
                            <span className="text-danger">
                              {errors.selectedCategories}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <button
                          onClick={this.handleSubmit}
                          type="button"
                          className="btn btn-primary waves-effect waves-light"
                        >
                          ENVOYER
                        </button>
                        &nbsp;
                        <button
                          type="button"
                          className="btn btn-secondary waves-effect waves-light"
                        >
                          ANNULER
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.movie.isLoading,
    movie: state.movie.movie,
    categories: state.category.categories,
    uploadPercent: state.movie.uploadPercent,
  };
};

const mapDispatchToProps = {
  getMovie,
  getCategories,
  updateMovie,
};

export default connect(mapStateToProps, mapDispatchToProps)(MovieEdit);
