import React, { Component } from "react";
import AUX from "../../hoc/Aux_";
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert/lib/dist/SweetAlert";
import {
  getMoviePart,
  updateMoviePart,
} from "../../store/actions/movieActions";
import { connect } from "react-redux";
import Select from "react-select";

class PartEdit extends Component {
  state = {
    alert: null,
    isLoading: false,
    id: "",
    name: "",
    description: "",
    path: "",
    coverSM: "",
    coverMD: "",
    newPath: "",
    newCoverSM: "",
    newCoverMD: "",
    movie_id: "",
    errors: {
      name: "",
      description: "",
      path: "",
      coverSM: "",
      coverMD: "",
      newPath: "",
      newCoverSM: "",
      newCoverMD: "",
    },
    episodes: [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
    ],
    isSubmit: false,
  };
  componentDidMount() {
    this.setState({
      isLoading: true,
    });
    this.props
      .getMoviePart({ partId: this.props.match.params.partId })
      .then(() => {
        this.setState({
          isLoading: false,
          id: this.props.part.id,
          name: this.props.part.name,
          description: this.props.part.description,
          path: this.props.part.path,
          coverSM: this.props.part.coverSM
            ? "https://itv-lm.paraclet-bk.com/storage/" +
              this.props.part.coverSM
            : null,
          coverMD: this.props.part.coverMD
            ? "https://itv-lm.paraclet-bk.com/storage/" +
              this.props.part.coverMD
            : null,
          movie_id: this.props.part.movie_id,
        });
      })
      .catch(() =>
        this.setState({
          isLoading: false,
        })
      );
  }
  validator(name, value, type, files) {
    if (type === "file") {
      let errors = this.state.errors;
      switch (name) {
        case "newCoverSM":
          if (files.length > 0) {
            errors.newCoverSM =
              files[0].size > 300000
                ? "Le poids de l'image de couverture doit être inférieur à 300Ko."
                : "";
          } else {
            // errors.cover = "Veuillez télécharger l'image de couverture svp.";
            errors.newCoverSM = "";
          }
          break;
        case "newCoverMD":
          if (files.length > 0) {
            errors.newCoverMD =
              files[0].size > 300000
                ? "Le poids de l'image de couverture doit être inférieur à 300Ko."
                : "";
          } else {
            // errors.cover = "Veuillez télécharger l'image de couverture svp.";
            errors.newCoverMD = "";
          }
          break;
        case "newPath":
          if (files.length > 0) {
            errors.newPath =
              files[0].type !== "video/mp4"
                ? "Le format de video accepté est le MP4."
                : "";
          } else {
            // errors.video = "Veuillez télécharger la video svp.";
            errors.newPath = "";
          }
          break;
        default:
          break;
      }
    } else {
      let errors = this.state.errors;
      switch (name) {
        case "name":
          errors.name =
            value.length < 5
              ? "Le nom doit contenir 5 caractères minimum !"
              : "";
          break;
        case "description":
          errors.description =
            value.length < 10
              ? "Le nom doit contenir 10 caractères minimum !"
              : "";
          break;
        case "type":
          errors.type =
            value.length !== 5
              ? "Vérifiez avoir bien rempli ce champ svp !"
              : "";
          break;
        default:
          break;
      }
    }
  }
  handleInputChange = (e) => {
    const { name, value, type, files, checked } = e.target;
    let errors = this.state.errors;
    if (type === "file") {
      this.validator(name, value, type, files);
      this.setState({ errors, [name]: files[0] }, () => {
        console.log(errors);
      });
    } else if (type === "checkbox") {
      this.validator(name, value, type, files, checked);
      this.setState({ errors, [name]: checked }, () => {
        // console.log(errors);
      });
    } else {
      this.validator(name, value, type, files);
      this.setState({ errors, [name]: value }, () => {
        console.log(errors);
      });
    }
  };
  handleSweetAlert(type) {
    let videoId = this.props.part.movie_id;
    const successAlert = () => (
      <SweetAlert
        success
        title=""
        onConfirm={() => this.props.history.push("/details-video/" + videoId)}
      >
        Information enregistrée avec succès !
      </SweetAlert>
    );
    const errorAlert = () => (
      <SweetAlert error title="" onConfirm={() => this.hideAlert()}>
        Oups, une erreur a été détecté.
      </SweetAlert>
    );
    this.setState({
      alert: type === "success" ? successAlert() : errorAlert(),
    });
  }
  hideAlert() {
    this.setState({ alert: null });
  }
  handleSubmit = () => {
    this.setState({
      isLoading: true,
      isSubmit: true,
    });
    const {
      id,
      name,
      description,
      path,
      coverSM,
      coverMD,
      newCoverSM,
      newCoverMD,
      newPath,
    } = this.state;
    const moviePartForm = new FormData();
    moviePartForm.append("id", id);
    moviePartForm.append("name", name);
    moviePartForm.append("description", description);
    moviePartForm.append("path", newPath);
    moviePartForm.append("coverSM", newCoverSM);
    moviePartForm.append("coverMD", newCoverMD);
    this.props
      .updateMoviePart(moviePartForm, id)
      .then(() => {
        this.setState({
          isLoading: false,
          isSubmit: false,
        });
        this.handleSweetAlert("success");
      })
      .catch(() => {
        this.setState({
          isLoading: false,
          isSubmit: false,
        });
        this.handleSweetAlert("error");
      });
  };
  render() {
    const {
      id,
      name,
      description,
      path,
      coverSM,
      coverMD,
      newPath,
      newCoverSM,
      newCoverMD,
      errors,
      alert,
      isLoading,
      isSubmit,
    } = this.state;
    return (
      <AUX>
        {alert}
        <div className="row">
          <div className="col-sm-12">
            <div className="page-title-box">
              <h4 className="page-title">Modification vidéo</h4>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="javascript:void(0);">Tableau de bord</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="javascript:void(0);">Vidéos</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="javascript:void(0);">Détails</Link>
                </li>
                <li className="breadcrumb-item active">
                  Modification d'épisode
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card m-b-20">
              <div className="card-body">
                {isLoading ? (
                  <div>
                    <div className="alert alert-info text-center">
                      Chargement en cours ...
                    </div>
                    {isSubmit && (
                      <div className="progress" style={{ height: "20px" }}>
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: this.props.uploadPartPercent + "%" }}
                          aria-valuenow={this.props.uploadPartPercent}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          {this.props.uploadPartPercent + "%"}
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <form>
                    <div className="row">
                      <div className="col-md-8">
                        <div className="form-group">
                          <label>Nom</label>
                          <input
                            type="text"
                            name="name"
                            className={
                              errors.name.length > 0
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            value={name}
                            onChange={this.handleInputChange}
                            noValidate
                          />
                          {errors.name.length > 0 && (
                            <span className="invalid-feedback">
                              {errors.name}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          {coverSM !== "" ? (
                            <div>
                              <button
                                className="btn btn-link"
                                type="button"
                                data-toggle="modal"
                                data-target="#coverModal"
                                style={{ paddingLeft: 0 }}
                              >
                                Image (Image - Petit Format)
                              </button>
                              <div
                                className="modal fade"
                                id="coverModal"
                                tabIndex="-1"
                                role="dialog"
                                aria-labelledby="coverModalLabel"
                                aria-hidden="true"
                              >
                                <div className="modal-dialog" role="document">
                                  <div className="modal-content">
                                    <div className="modal-header">
                                      <h5
                                        className="modal-title"
                                        id="coverModalLabel"
                                      >
                                        Image (Image - Petit Format)
                                      </h5>
                                      <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                      >
                                        <span aria-hidden="true">&times;</span>
                                      </button>
                                    </div>
                                    <div className="modal-body">
                                      <img
                                        src={coverSM}
                                        style={{
                                          width: "100%",
                                          height: "auto",
                                        }}
                                      />
                                    </div>
                                    <div className="modal-footer">
                                      <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-dismiss="modal"
                                      >
                                        FERMER
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <label>Image (Image - Petit Format)</label>
                          )}
                          <input
                            name="newCoverSM"
                            id="newCoverSM"
                            type="file"
                            className={
                              errors.newCoverSM.length > 0
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            onChange={this.handleInputChange}
                            noValidate
                          />
                          {errors.newCoverSM.length > 0 && (
                            <span className="invalid-feedback">
                              {errors.newCoverSM}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          {coverMD !== "" ? (
                            <div>
                              <button
                                className="btn btn-link"
                                type="button"
                                data-toggle="modal"
                                data-target="#coverModalMD"
                                style={{ paddingLeft: 0 }}
                              >
                                Bannière (Image - Format Moyen)
                              </button>
                              <div
                                className="modal fade"
                                id="coverModalMD"
                                tabIndex="-1"
                                role="dialog"
                                aria-labelledby="coverModalLabelMD"
                                aria-hidden="true"
                              >
                                <div className="modal-dialog" role="document">
                                  <div className="modal-content">
                                    <div className="modal-header">
                                      <h5
                                        className="modal-title"
                                        id="coverModalLabel"
                                      >
                                        Bannière (Image - Petit Format)
                                      </h5>
                                      <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                      >
                                        <span aria-hidden="true">&times;</span>
                                      </button>
                                    </div>
                                    <div className="modal-body">
                                      <img
                                        src={coverMD}
                                        style={{
                                          width: "100%",
                                          height: "auto",
                                        }}
                                      />
                                    </div>
                                    <div className="modal-footer">
                                      <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-dismiss="modal"
                                      >
                                        FERMER
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <label>Bannière (Image - Petit Format)</label>
                          )}
                          <input
                            name="newCoverMD"
                            id="newCoverMD"
                            type="file"
                            className={
                              errors.newCoverMD.length > 0
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            onChange={this.handleInputChange}
                            noValidate
                          />
                          {errors.newCoverMD.length > 0 && (
                            <span className="invalid-feedback">
                              {errors.newCoverMD}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Description</label>
                          <textarea
                            name="description"
                            className={
                              errors.description.length > 0
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            cols="30"
                            rows="10"
                            value={description}
                            onChange={this.handleInputChange}
                            noValidate
                          ></textarea>
                          {errors.description.length > 0 && (
                            <span className="invalid-feedback">
                              {errors.description}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          {path !== "" ? (
                            <div>
                              <button
                                className="btn btn-link"
                                type="button"
                                data-toggle="modal"
                                data-target="#pathModal"
                                style={{ paddingLeft: 0 }}
                              >
                                Video
                              </button>
                              <div
                                className="modal fade"
                                id="pathModal"
                                tabIndex="-1"
                                role="dialog"
                                aria-labelledby="pathModalLabel"
                                aria-hidden="true"
                              >
                                <div className="modal-dialog" role="document">
                                  <div className="modal-content">
                                    <div className="modal-header">
                                      <h5
                                        className="modal-title"
                                        id="pathModalLabel"
                                      >
                                        Video
                                      </h5>
                                      <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                      >
                                        <span aria-hidden="true">&times;</span>
                                      </button>
                                    </div>
                                    <div className="modal-body">
                                      <video
                                        controls
                                        width="100%"
                                        height="auto"
                                        controlsList="nodownload"
                                      >
                                        <source src={path} type="video/mp4" />
                                      </video>
                                    </div>
                                    <div className="modal-footer">
                                      <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-dismiss="modal"
                                      >
                                        FERMER
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <label>Video</label>
                          )}
                          <input
                            name="newPath"
                            id="newPath"
                            type="file"
                            className={
                              errors.newPath.length > 0
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            onChange={this.handleInputChange}
                            noValidate
                          />
                          {errors.newPath.length > 0 && (
                            <span className="invalid-feedback">
                              {errors.newPath}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <button
                          onClick={this.handleSubmit}
                          type="button"
                          className="btn btn-primary waves-effect waves-light"
                        >
                          ENVOYER
                        </button>
                        &nbsp;
                        <button
                          type="button"
                          className="btn btn-secondary waves-effect waves-light"
                        >
                          ANNULER
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.video.isLoading,
    part: state.video.part,
    uploadPartPercent: state.video.uploadPartPercent,
  };
};

const mapDispatchToProps = {
  getMoviePart,
  updateMoviePart,
};

export default connect(mapStateToProps, mapDispatchToProps)(PartEdit);
