import React, { Component } from "react";
import AUX from "../../hoc/Aux_";
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert/lib/dist/SweetAlert";
import { connect } from "react-redux";
import { storeMoviePart } from "../../store/actions/movieActions";

class PartAdd extends Component {
  state = {
    alert: null,
    isLoading: false,
    name: "",
    description: "",
    path: "",
    coverSM: "",
    coverMD: "",
    episode: "",
    errors: {
      name: "",
      description: "",
      path: "",
      coverSM: "",
      coverMD: "",
      episode: "",
    },
    episodes: [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
    ],
    isSubmit: false,
  };
  componentDidMount() {
    //
  }
  validator(name, value, type, files, checked) {
    if (type === "file") {
      let errors = this.state.errors;
      switch (name) {
        case "coverSM":
          if (files.length > 0) {
            errors.coverSM =
              files[0].size > 300000
                ? "Le poids de l'image de couverture doit être inférieur à 300Ko."
                : "";
          } else {
            errors.coverSM = "Veuillez télécharger l'image de couverture svp.";
          }
          break;
        case "coverMD":
          if (files.length > 0) {
            errors.coverMD =
              files[0].size > 300000
                ? "Le poids de l'image de couverture doit être inférieur à 300Ko."
                : "";
          } else {
            errors.coverMD = "Veuillez télécharger l'image de couverture svp.";
          }
          break;
        case "path":
          if (files.length > 0) {
            errors.path =
              files[0].type !== "video/mp4"
                ? "Le format de video accepté est le MP4."
                : "";
          } else {
            errors.path = "Veuillez télécharger la video svp.";
          }
          break;
        default:
          break;
      }
    } else {
      let errors = this.state.errors;
      switch (name) {
        case "name":
          errors.name =
            value.length < 5
              ? "Le nom doit contenir 5 caractères minimum !"
              : "";
          break;
        case "description":
          errors.description =
            value.length < 10
              ? "La description doit contenir 10 caractères minimum !"
              : "";
          break;
        case "episode":
          errors.episode =
            value.length <= 0
              ? "Veuillez bien renseigner cette valeur svp."
              : "";
          break;
        default:
          break;
      }
    }
  }
  handleInputChange = (e) => {
    const { name, value, type, files } = e.target;
    let errors = this.state.errors;
    if (type === "file") {
      this.validator(name, value, type, files);
      this.setState({ errors, [name]: files[0] }, () => {
        // console.log(errors);
      });
    } else {
      this.validator(name, value, type, files);
      this.setState({ errors, [name]: value }, () => {
        // console.log(errors);
      });
    }
  };

  handleSweetAlert(type, text) {
    const successAlert = () => (
      <SweetAlert
        success
        title=""
        onConfirm={() =>
          this.props.history.push(
            "/details-video/" + this.props.match.params.movieId
          )
        }
      >
        {text !== null ? text : "Information enregistrée avec succès !"}
      </SweetAlert>
    );
    const errorAlert = () => (
      <SweetAlert error title="" onConfirm={() => this.hideAlert()}>
        {text !== null ? text : "Oups, une erreur a été détecté !"}
      </SweetAlert>
    );
    this.setState({
      alert: type === "success" ? successAlert() : errorAlert(),
    });
  }
  hideAlert() {
    this.setState({ alert: null });
  }
  handleSubmit = (e) => {
    e.preventDefault();
    const { name, description, path, coverSM, coverMD, episode } = this.state;
    if (
      name === "" ||
      description === "" ||
      path === "" ||
      coverSM === "" ||
      coverMD === "" ||
      episode === ""
    ) {
      return this.handleSweetAlert(
        "error",
        "Veuillez correctement remplir le formulaire svp."
      );
    }
    this.setState({
      isLoading: true,
      isSubmit: true,
    });
    const videoForm = new FormData();
    videoForm.append("name", name);
    videoForm.append("description", description);
    videoForm.append("path", path);
    videoForm.append("coverSM", coverSM);
    videoForm.append("coverMD", coverMD);
    videoForm.append("episode", episode);
    videoForm.append("movie_id", this.props.match.params.movieId);
    this.props
      .storeMoviePart(videoForm)
      .then(() => {
        this.setState({
          isLoading: false,
          isSubmit: false,
        });
        this.handleSweetAlert("success");
      })
      .catch(() => {
        this.setState({
          isLoading: false,
          isSubmit: false,
        });
        this.handleSweetAlert("error");
      });
  };
  render() {
    const {
      name,
      description,
      episode,
      episodes,
      alert,
      isLoading,
      errors,
      isSubmit,
    } = this.state;
    return (
      <AUX>
        {alert}
        <div className="row">
          <div className="col-sm-12">
            <div className="page-title-box">
              <h4 className="page-title">Nouvelle vidéo</h4>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="javascript:void(0);">Tableau de bord</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/videos">Videos</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link
                    to={"/details-video/" + this.props.match.params.movieId}
                  >
                    Détails
                  </Link>
                </li>
                <li className="breadcrumb-item active">Nouvelle partie</li>
              </ol>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card m-b-20">
              <div className="card-body">
                {isLoading ? (
                  <div>
                    <div className="alert alert-info text-center">
                      Chargement en cours ...
                    </div>
                    {isSubmit && (
                      <div className="progress" style={{ height: "20px" }}>
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: this.props.uploadPartPercent + "%" }}
                          aria-valuenow={this.props.uploadPartPercent}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          {this.props.uploadPartPercent + "%"}
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <form onSubmit={this.handleSubmit} noValidate>
                    <div className="row">
                      <div className="col-md-8">
                        <div className="form-group">
                          <label>Nom</label>
                          <input
                            type="text"
                            name="name"
                            className={
                              errors.name.length > 0
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            value={name}
                            onChange={this.handleInputChange}
                            noValidate
                            // required
                          />
                          {errors.name.length > 0 && (
                            <span className="invalid-feedback">
                              {errors.name}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Episode N° ?</label>
                          <select
                            name="episode"
                            id=""
                            className={
                              errors.episode.length > 0
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            onChange={this.handleInputChange}
                            value={episode}
                          >
                            <option value="">--</option>
                            {episodes.map((episodeKey, key) => (
                              <option value={episodeKey} key={key}>
                                {episodeKey}
                              </option>
                            ))}
                          </select>
                          {errors.episode.length > 0 && (
                            <span className="invalid-feedback">
                              {errors.episode}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Image (Image - Petit Format)</label>
                          <input
                            name="coverSM"
                            id="coverSM"
                            type="file"
                            className={
                              errors.coverSM.length > 0
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            onChange={this.handleInputChange}
                            noValidate
                            // required
                          />
                          {errors.coverSM.length > 0 && (
                            <span className="invalid-feedback">
                              {errors.coverSM}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Bannière (Image - Grand Format)</label>
                          <input
                            name="coverMD"
                            id="coverMD"
                            type="file"
                            className={
                              errors.coverMD.length > 0
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            onChange={this.handleInputChange}
                            noValidate
                            // required
                          />
                          {errors.coverMD.length > 0 && (
                            <span className="invalid-feedback">
                              {errors.coverMD}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Video</label>
                          <input
                            name="path"
                            id="path"
                            type="file"
                            className={
                              errors.path.length > 0
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            onChange={this.handleInputChange}
                            noValidate
                            // required
                          />
                          {errors.path.length > 0 && (
                            <span className="invalid-feedback">
                              {errors.description}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Description</label>
                          <textarea
                            name="description"
                            className={
                              errors.description.length > 0
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            cols="30"
                            rows="10"
                            value={description}
                            onChange={this.handleInputChange}
                            noValidate
                            // required
                          ></textarea>
                          {errors.description.length > 0 && (
                            <span className="invalid-feedback">
                              {errors.description}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <button
                        type="submit"
                        className="btn btn-primary waves-effect waves-light"
                      >
                        ENVOYER
                      </button>
                      &nbsp;
                      <button
                        type="reset"
                        className="btn btn-secondary waves-effect waves-light"
                      >
                        ANNULER
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    uploadPartPercent: state.video.uploadPartPercent,
  };
};

const mapDispatchToProps = {
  storeMoviePart,
};

export default connect(mapStateToProps, mapDispatchToProps)(PartAdd);
