import React, { Component } from "react";
import AUX from "../../hoc/Aux_";
import { Link } from "react-router-dom";
import Axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert/lib/dist/SweetAlert";

class UserAdd extends Component {
  state = {
    name: "",
    email: "",
    phone_numbers: "",
    role: "",
    password: "",
    name_err: "",
    email_err: "",
    phone_numbers_err: "",
    role_err: "",
    password_err: "",
    isLoading: false
  };
  handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value });
    let name_err = e.target.name + "_err";
    if (e.target.value === "") {
      this.setState({ [name_err]: "Ce champ est obligatoire" });
    } else if (e.target.value.length > 3 && e.target.value.length < 100) {
      this.setState({ [name_err]: "" });
    } else {
      this.setState({ [name_err]: "This value should be between 6 and 100." });
    }
  };
  handleSubmit = () => {
    const { name, email, phone_numbers, role, password } = this.state;
    let user = { name, email, phone_numbers, role, password };
    Axios.post("https://itv-lm.paraclet-bk.com/api/users", user)
      .then(response => {
        console.log(response.data);
        this.handleSweetAlert("success");
      })
      .catch(error => {
        console.log(error);
        this.handleSweetAlert("error");
      });
  };
  handleSweetAlert(type) {
    const successAlert = () => (
      <SweetAlert
        success
        title=""
        onConfirm={() => this.props.history.push("/users")}
      >
        Information enregistrée avec succès !
      </SweetAlert>
    );
    const errorAlert = () => (
      <SweetAlert error title="" onConfirm={() => this.hideAlert()}>
        Oups, une erreur a été détecté.
      </SweetAlert>
    );
    this.setState({
      alert: type === "success" ? successAlert() : errorAlert()
    });
  }
  hideAlert() {
    this.setState({ alert: null });
  }
  render() {
    const {
      name,
      email,
      phone_numbers,
      role,
      password,
      name_err,
      email_err,
      phone_numbers_err,
      role_err,
      password_err,
      alert
    } = this.state;
    return (
      <AUX>
        {alert}
        <div className="row">
          <div className="col-sm-12">
            <div className="page-title-box">
              <h4 className="page-title">Comptes</h4>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="javascript:void(0);">Tableau de bord</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="javascript:void(0);">Comptes</Link>
                </li>
                <li className="breadcrumb-item active">Compte</li>
              </ol>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card m-b-20">
              <div className="card-body">
                <form className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Nom</label>
                      <input
                        type="text"
                        name="name"
                        className={
                          name_err !== ""
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        value={name}
                        onChange={this.handleInputChange}
                      />
                      <span className="invalid-feedback" id="err">
                        {name_err}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        type="email"
                        name="email"
                        className={
                          email_err !== ""
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        value={email}
                        onChange={this.handleInputChange}
                      />
                      <span className="invalid-feedback" id="err">
                        {email_err}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Contacts</label>
                      <input
                        type="text"
                        name="phone_numbers"
                        className={
                          phone_numbers_err !== ""
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        value={phone_numbers}
                        onChange={this.handleInputChange}
                      />
                      <span className="invalid-feedback" id="err">
                        {phone_numbers_err}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Mot de passe</label>
                      <input
                        type="password"
                        name="password"
                        className={
                          password_err !== ""
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        value={password}
                        onChange={this.handleInputChange}
                      />
                      <span className="invalid-feedback" id="err">
                        {password_err}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Rôle</label>
                      <select
                        name="role"
                        className={
                          role_err !== ""
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        value={role}
                        onChange={this.handleInputChange}
                      >
                        <option value="">--</option>
                        <option value="ADMIN">ADMIN</option>
                        <option value="USER">USER</option>
                      </select>
                      <span className="invalid-feedback" id="err">
                        {role_err}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div>
                      {(name, email, phone_numbers, password, role === "") ? (
                        <button
                          type="button"
                          className="btn btn-primary waves-effect waves-light"
                          disabled
                        >
                          ENVOYER
                        </button>
                      ) : (
                        <button
                          onClick={this.handleSubmit}
                          type="button"
                          className="btn btn-primary waves-effect waves-light"
                        >
                          ENVOYER
                        </button>
                      )}
                      &nbsp;
                      <button
                        type="reset"
                        className="btn btn-secondary waves-effect m-l-5"
                      >
                        ANNULER
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

export default UserAdd;
