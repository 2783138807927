import React, { Component } from "react";
import AUX from "../../hoc/Aux_";
import { Link } from "react-router-dom";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert/lib/dist/SweetAlert";

class UserList extends Component {
  state = {
    users: [],
    isLoading: false,
    alert: null
  };
  componentDidMount() {
    this.handleUsers();
  }
  handleUsers() {
    axios
      .get("https://itv-lm.paraclet-bk.com/api/users?role=ADMIN")
      .then(response => {
        this.setState({
          users: response.data
        });
      })
      .catch(error => {
        console.log(error);
      });
  }
  handleDeleteUser(categoryId) {
    this.setState({
      isLoading: true
    });
    axios
      .delete("https://itv-lm.paraclet-bk.com/api/users/" + categoryId)
      .then(() => {
        this.handleSuccess();
        this.setState({ isLoading: false });
        this.handleUsers();
      })
      .catch(error => {
        console.log(error);
        this.setState({
          isLoading: false
        });
      });
  }
  handleConfirm(userId) {
    const WarningText = () => (
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Oui"
        cancelBtnText="Non"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        title=""
        onCancel={() => this.hideAlert()}
        onConfirm={() => this.handleDeleteUser(userId)}
      >
        Êtes-vous sûre de vouloir supprimer cette catégorie ?
      </SweetAlert>
    );
    this.setState({ alert: WarningText() });
  }
  handleSuccess() {
    const SuccessText = () => (
      <SweetAlert success title="" onConfirm={() => this.hideAlert()}>
        Catégorie supprimé avec succès !
      </SweetAlert>
    );
    this.setState({ alert: SuccessText() });
  }
  hideAlert() {
    this.setState({ alert: null });
  }
  render() {
    const { users, isLoading, alert } = this.state;
    return (
      <AUX>
        {alert}
        <div className="row">
          <div className="col-sm-12">
            <div className="page-title-box">
              <h4 className="page-title">Comptes</h4>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="javascript:void(0);">Tableau de bord</Link>
                </li>
                <li className="breadcrumb-item active">Comptes</li>
              </ol>
              <div className="state-information d-none d-sm-block">
                <Link to="/add-user" className="btn btn-info">
                  Nouveau compte
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card m-b-20">
              <div className="card-body">
                {isLoading ? (
                  <div className="alert alert-primary text-center">
                    Chargement en cours ...
                  </div>
                ) : (
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Nom complet</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.map((user, key) => (
                        <tr key={key}>
                          <th scope="row">{key + 1}</th>
                          <td>{user.name}</td>
                          <td>{user.email}</td>
                          <td>{user.role}</td>
                          <td>
                            <Link
                              to={"/user-details/" + user.id}
                              className="btn btn-info btn-sm"
                            >
                              <i className="fa fa-eye"></i>
                            </Link>
                            &nbsp;
                            <Link
                              to={"/edit-user/" + user.id}
                              className="btn btn-warning btn-sm"
                            >
                              <i className="fa fa-edit"></i>
                            </Link>
                            &nbsp;
                            <button
                              className="btn btn-danger btn-sm waves-effect waves-light"
                              onClick={() => this.handleConfirm(user.id)}
                            >
                              <i className="fa fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

export default UserList;
