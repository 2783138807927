import axios from "axios";
import { returnErrors } from "./errorActions";

import {
  GET_CATEGORIES_LOADING,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAIL,
  DELETE_CATEGORY_LOADING,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAIL,
  STORE_CATEGORY_LOADING,
  STORE_CATEGORY_SUCCESS,
  STORE_CATEGORY_FAIL,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAIL,
  UPDATE_CATEGORY_LOADING,
  GET_CATEGORY_LOADING,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_FAIL,
  ATTACH_CATEGORY_MOVIE_LOADING,
  ATTACH_CATEGORY_MOVIE_SUCCESS,
  ATTACH_CATEGORY_MOVIE_FAIL,
  DETACH_CATEGORY_MOVIE_LOADING,
  DETACH_CATEGORY_MOVIE_SUCCESS,
  DETACH_CATEGORY_MOVIE_FAIL
} from "./types";

export const getCategories = () => (dispatch, getState) => {
  dispatch({ type: GET_CATEGORIES_LOADING });
  return new Promise((resolve, reject) => {
    axios
      .get(
        "https://itv-lm.paraclet-bk.com/api/categories",
        tokenConfig(getState)
      )
      .then(response => {
        resolve(
          dispatch({ type: GET_CATEGORIES_SUCCESS, payload: response.data })
        );
      })
      .catch(error => {
        dispatch(returnErrors(error.response.data, error.response.status));
        dispatch({ type: GET_CATEGORIES_FAIL });
        reject(error.response.data);
      });
  });
};

export const storeCategory = category => (dispatch, getState) => {
  dispatch({ type: STORE_CATEGORY_LOADING });
  return new Promise((resolve, reject) => {
    axios
      .post(
        "https://itv-lm.paraclet-bk.com/api/categories",
        category,
        tokenConfig(getState)
      )
      .then(response => {
        resolve(dispatch({ type: STORE_CATEGORY_SUCCESS }));
      })
      .catch(error => {
        dispatch(returnErrors(error.response.data, error.response.status));
        dispatch({ type: STORE_CATEGORY_FAIL });
        reject(error.response.data);
      });
  });
};

export const getCategory = ({ id }) => (dispatch, getState) => {
  dispatch({ type: GET_CATEGORY_LOADING });
  return new Promise((resolve, reject) => {
    axios
      .get(
        "https://itv-lm.paraclet-bk.com/api/categories/" + id,
        tokenConfig(getState)
      )
      .then(response => {
        resolve(
          dispatch({ type: GET_CATEGORY_SUCCESS, payload: response.data })
        );
      })
      .catch(error => {
        dispatch(returnErrors(error.response.data, error.response.status));
        dispatch({ type: GET_CATEGORY_FAIL });
        reject(error.response.data);
      });
  });
};

export const updateCategory = (id, category) => (dispatch, getState) => {
  dispatch({ type: UPDATE_CATEGORY_LOADING });
  return new Promise((resolve, reject) => {
    axios
      .put(
        "https://itv-lm.paraclet-bk.com/api/categories/" + id,
        category,
        tokenConfig(getState)
      )
      .then(response => {
        resolve(dispatch({ type: UPDATE_CATEGORY_SUCCESS }));
      })
      .catch(error => {
        dispatch(returnErrors(error.response.data, error.response.status));
        dispatch({ type: UPDATE_CATEGORY_FAIL });
        reject(error.response.data);
      });
  });
};

export const attachCategoryMovie = attachData => (dispatch, getState) => {
  dispatch({ type: ATTACH_CATEGORY_MOVIE_LOADING });
  return new Promise((resolve, reject) => {
    axios
      .post(
        "https://itv-lm.paraclet-bk.com/api/category-movie-attach",
        attachData,
        tokenConfig(getState)
      )
      .then(response => {
        resolve(dispatch({ type: ATTACH_CATEGORY_MOVIE_SUCCESS }));
      })
      .catch(error => {
        dispatch(returnErrors(error.response.data, error.response.status));
        dispatch({ type: ATTACH_CATEGORY_MOVIE_FAIL });
        reject(error.response.data);
      });
  });
};

export const detachCategoryMovie = detachData => (dispatch, getState) => {
  dispatch({ type: DETACH_CATEGORY_MOVIE_LOADING });
  return new Promise((resolve, reject) => {
    axios
      .post(
        "https://itv-lm.paraclet-bk.com/api/category-movie-detach",
        detachData,
        tokenConfig(getState)
      )
      .then(response => {
        resolve(dispatch({ type: DETACH_CATEGORY_MOVIE_SUCCESS }));
      })
      .catch(error => {
        dispatch(returnErrors(error.response.data, error.response.status));
        dispatch({ type: DETACH_CATEGORY_MOVIE_FAIL });
        reject(error.response.data);
      });
  });
};

export const deleteCategory = ({ categoryId }) => (dispatch, getState) => {
  dispatch({ type: DELETE_CATEGORY_LOADING });
  return new Promise((resolve, reject) => {
    axios
      .delete(
        "https://itv-lm.paraclet-bk.com/api/categories/" + categoryId,
        tokenConfig(getState)
      )
      .then(response => {
        resolve(dispatch({ type: DELETE_CATEGORY_SUCCESS }));
      })
      .catch(error => {
        dispatch(returnErrors(error.response.data, error.response.status));
        dispatch({ type: DELETE_CATEGORY_FAIL });
        reject(error.response.data);
      });
  });
};

export const tokenConfig = getState => {
  // Get token from localStorage
  const token = getState().auth.token;

  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  if (token) {
    config.headers["Authorization"] = "Bearer " + token;
  }

  return config;
};
