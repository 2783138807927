import React, { Component } from "react";
import AUX from "../../hoc/Aux_";
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert/lib/dist/SweetAlert";
import { getMovies, deleteMovie } from "../../store/actions/movieActions";
import { connect } from "react-redux";
import { MDBDataTable } from "mdbreact";

class MovieList extends Component {
  state = {
    movies: [],
    isLoading: false,
    displayType: "list",
    data: {},
    cards: [],
    cardsToDisplay: [],
    searchQuery: "",
    alert: null,
  };
  componentDidMount() {
    this.setState({
      isLoading: true,
    });
    this.getMovieDatatables()
      .then(() => {
        this.setState({
          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          isLoading: false,
        });
      });
  }
  getMovieDatatables() {
    return new Promise((resolve, reject) => {
      this.props
        .getMovies()
        .then(() => {
          let columns = [
            {
              label: "#",
              field: "key",
              sort: "asc",
            },
            {
              label: "Titre",
              field: "name",
              sort: "asc",
            },
            {
              label: "Description",
              field: "description",
              sort: "asc",
            },
            {
              label: "Type",
              field: "type",
              sort: "asc",
            },
            {
              label: "Catégories",
              field: "categories",
              sort: "asc",
            },
            {
              label: "Autres",
              field: "onSlider",
              sort: "asc",
            },
            {
              label: "Actions",
              field: "actions",
            },
          ];
          let rows = [];
          let cards = [];
          this.props.movies.map((movie, key) => {
            rows.push({
              key: key + 1,
              name: movie.name,
              description: movie.description.substring(0, 100),
              type: movie.type === "MOVIE" ? "Film" : "Série TV",
              onSlider: movie.onSlider === "1" ? "Bannière" : "",
              categories: movie.categories.map((category, key) =>
                key === 0 ? category.name : ", " + category.name
              ),
              actions: (
                <span>
                  <Link
                    to={"/details-movie/" + movie.id}
                    className="btn btn-info btn-sm"
                  >
                    <i className="fa fa-eye"></i>
                  </Link>{" "}
                  <Link
                    to={"/edit-movie/" + movie.id}
                    className="btn btn-warning btn-sm"
                  >
                    <i className="fa fa-edit"></i>
                  </Link>{" "}
                  <button
                    className="btn btn-danger btn-sm waves-effect waves-light"
                    onClick={() => this.handleConfirm(movie.id)}
                  >
                    <i className="fa fa-trash"></i>
                  </button>
                </span>
              ),
            });
            let categories = "";
            movie.categories.map((category, key) => {
              key === 0
                ? (categories = category.name)
                : (categories += ", " + category.name);
            });
            cards.push({
              id: movie.id,
              name: movie.name,
              path: movie.path,
              coverSM: movie.coverSM,
              coverMD: movie.coverMD,
              onSlider: movie.onSlider,
              description: movie.description.substring(0, 100),
              type: movie.type === "MOVIE" ? "Film" : "Série TV",
              categories: categories,
            });
          });
          let data = {
            columns: columns,
            rows: rows,
          };
          this.setState({
            data: data,
            cards: cards,
            cardsToDisplay: cards,
          });
          resolve(this.state.data);
        })
        .catch(() => {
          reject("Erreur de chargement de la liste de movies");
        });
    });
  }
  handleSearchCard = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    if (!value || value.length < 3) {
      this.setState({
        cardsToDisplay: [...this.state.cards],
      });
    } else {
      let cardsToDisplay = this.state.cards.filter(
        (movie) =>
          movie.name.toLowerCase().includes(value.toLowerCase()) ||
          movie.type.toLowerCase().includes(value.toLowerCase()) ||
          movie.categories.toLowerCase().includes(value.toLowerCase())
      );
      this.setState({
        cardsToDisplay,
      });
    }
  };
  handleDeleteMovie(movieId) {
    this.setState({
      isLoading: true,
    });
    this.props
      .deleteMovie(movieId)
      .then(() => {
        this.handleSuccess();
        this.getMovieDatatables();
        this.setState({
          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          isLoading: false,
        });
      });
  }
  handleConfirm(movieId) {
    const WarningText = () => (
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Oui"
        cancelBtnText="Non"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        title=""
        onCancel={() => this.hideAlert()}
        onConfirm={() => this.handleDeleteMovie(movieId)}
      >
        Êtes-vous sûre de vouloir supprimer cette movie ?
      </SweetAlert>
    );
    this.setState({ alert: WarningText() });
  }
  handleSuccess() {
    const SuccessText = () => (
      <SweetAlert success title="" onConfirm={() => this.hideAlert()}>
        Vidéo supprimé avec succès !
      </SweetAlert>
    );
    this.setState({ alert: SuccessText() });
  }
  hideAlert() {
    this.setState({ alert: null });
  }
  onChangeDisplayType(type) {
    this.setState({
      displayType: type,
    });
  }
  render() {
    const {
      alert,
      displayType,
      data,
      isLoading,
      cardsToDisplay,
      searchQuery,
    } = this.state;
    const { movies } = this.props;
    return (
      <AUX>
        {alert}
        <div className="row">
          <div className="col-sm-12">
            <div className="page-title-box">
              <h4 className="page-title">Vidéos</h4>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="javascript:void(0);">Tableau de bord</Link>
                </li>
                <li className="breadcrumb-item active">Vidéos</li>
              </ol>
              <div className="state-information d-none d-sm-block">
                <Link to="/add-movie" className="btn btn-info">
                  Nouvelle vidéo
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card m-b-20">
              <div className="card-body">
                {isLoading || data.length === 0 ? (
                  <div className="alert alert-info text-center">
                    Chargement en cours ...
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-md-10">
                      <div className="btn-group" role="group" aria-label="">
                        <button
                          onClick={() => this.onChangeDisplayType("list")}
                          className={
                            displayType === "list"
                              ? "btn btn-outline-secondary btn-sm active"
                              : "btn btn-outline-secondary btn-sm"
                          }
                        >
                          <i className="ti-list"></i>
                        </button>
                        <button
                          onClick={() => this.onChangeDisplayType("card")}
                          className={
                            displayType === "card"
                              ? "btn btn-outline-secondary btn-sm active"
                              : "btn btn-outline-secondary btn-sm"
                          }
                        >
                          <i className="ti-layout-grid3"></i>
                        </button>
                      </div>
                    </div>
                    {displayType === "card" && (
                      <div className="col-md-2 float-right">
                        <div className="form-group">
                          <input
                            type="text"
                            placeholder="Recherche ..."
                            name="searchQuery"
                            value={searchQuery}
                            onChange={this.handleSearchCard}
                            className="form-control form-control-sm"
                          />
                        </div>
                      </div>
                    )}

                    {displayType === "list" ? (
                      <div className="col-md-12">
                        <br />
                        <MDBDataTable bordered hover data={data} />
                      </div>
                    ) : (
                      <div className="col-md-12">
                        <br />
                        <div className="card-deck">
                          {cardsToDisplay.length > 0 &&
                            cardsToDisplay.map((card, key) => (
                              <div className="col-md-4 pb-5" key={card.id}>
                                <Link
                                  style={{ textDecorationColor: "" }}
                                  to={"/details-movie/" + card.id}
                                >
                                  <div className="card m-b-30">
                                    <img
                                      className="card-img-top img-fluid"
                                      src={
                                        "https://itv-lm.paraclet-bk.com/storage/" +
                                        card.coverMD
                                      }
                                      alt=""
                                    />
                                    <div className="card-body">
                                      <span className="badge badge-danger font-14 mb-2">
                                        {card.type === "MOVIE"
                                          ? "Film"
                                          : "Série TV"}
                                      </span>{" "}
                                      {card.onSlider === "1" ? (
                                        <span className="badge badge-danger font-14 mb-2">
                                          Bannière
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                      <h4 className="card-title font-16 mt-0">
                                        {card.name}
                                      </h4>
                                      <p className="card-text">
                                        {card.description.substring(0, 100)}
                                      </p>
                                      <p className="card-text">
                                        {card.categories}
                                      </p>
                                      <p className="card-text">
                                        <small className="text-muted">
                                          {card.created_at}
                                        </small>
                                      </p>
                                      <hr />
                                      <div className="text-center">
                                        <Link
                                          to={"/edit-movie/" + card.id}
                                          className="btn btn-warning btn-sm"
                                        >
                                          <i className="fa fa-edit"></i>
                                        </Link>{" "}
                                        <button
                                          className="btn btn-danger btn-sm waves-effect waves-light"
                                          onClick={() =>
                                            this.handleConfirm(card.id)
                                          }
                                        >
                                          <i className="fa fa-trash"></i>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </Link>{" "}
                              </div>
                            ))}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.movie.isLoading,
    movies: state.movie.movies,
  };
};

const mapDispatchToProps = {
  getMovies,
  deleteMovie,
};

export default connect(mapStateToProps, mapDispatchToProps)(MovieList);
