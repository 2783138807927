export const SIDE_BAR = "side_bar";
export const TOP_BAR = "top_bar";
export const LOGINPAGE = "loginpage";
export const REG_PAGE = "reg_page";
export const SWITCHTOREG = "switch_reg";
export const SWITCHTOLOGIN = "switch_login";
export const RECOVER_PASS = "recover_pass";
export const FOOTER = "footer";

export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const GET_CATEGORIES_LOADING = "GET_CATEGORIES_LOADING";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const GET_CATEGORIES_FAIL = "GET_CATEGORIES_FAIL";

export const STORE_CATEGORY_LOADING = "STORE_CATEGORY_LOADING";
export const STORE_CATEGORY_SUCCESS = "STORE_CATEGORY_SUCCESS";
export const STORE_CATEGORY_FAIL = "STORE_CATEGORY_FAIL";

export const GET_CATEGORY_LOADING = "GET_CATEGORY_LOADING";
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS";
export const GET_CATEGORY_FAIL = "GET_CATEGORY_FAIL";

export const UPDATE_CATEGORY_LOADING = "UPDATE_CATEGORY_LOADING";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_FAIL = "UPDATE_CATEGORY_FAIL";

export const DELETE_CATEGORY_LOADING = "DELETE_CATEGORY_LOADING";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_FAIL = "DELETE_CATEGORY_FAIL";

export const ATTACH_CATEGORY_MOVIE_LOADING = "ATTACH_CATEGORY_MOVIE_LOADING";
export const ATTACH_CATEGORY_MOVIE_SUCCESS = "ATTACH_CATEGORY_MOVIE_SUCCESS";
export const ATTACH_CATEGORY_MOVIE_FAIL = "ATTACH_CATEGORY_MOVIE_FAIL";

export const DETACH_CATEGORY_MOVIE_LOADING = "DETACH_CATEGORY_MOVIE_LOADING";
export const DETACH_CATEGORY_MOVIE_SUCCESS = "DETACH_CATEGORY_MOVIE_SUCCESS";
export const DETACH_CATEGORY_MOVIE_FAIL = "DETACH_CATEGORY_MOVIE_FAIL";

export const GET_MOVIES_LOADING = "GET_MOVIES_LOADING";
export const GET_MOVIES_SUCCESS = "GET_MOVIES_SUCCESS";
export const GET_MOVIES_FAIL = "GET_MOVIES_FAIL";

export const STORE_MOVIE_LOADING = "STORE_MOVIE_LOADING";
export const STORE_MOVIE_SUCCESS = "STORE_MOVIE_SUCCESS";
export const STORE_MOVIE_FAIL = "STORE_MOVIE_FAIL";

export const GET_MOVIE_LOADING = "GET_MOVIE_LOADING";
export const GET_MOVIE_SUCCESS = "GET_MOVIE_SUCCESS";
export const GET_MOVIE_FAIL = "GET_MOVIE_FAIL";

export const UPDATE_MOVIE_LOADING = "UPDATE_MOVIE_LOADING";
export const UPDATE_MOVIE_SUCCESS = "UPDATE_MOVIE_SUCCESS";
export const UPDATE_MOVIE_FAIL = "UPDATE_MOVIE_FAIL";

export const DELETE_MOVIE_LOADING = "DELETE_MOVIE_LOADING";
export const DELETE_MOVIE_SUCCESS = "DELETE_MOVIE_SUCCESS";
export const DELETE_MOVIE_FAIL = "DELETE_MOVIE_FAIL";

export const MOVIE_UPLOAD_LOADING = "MOVIE_UPLOAD_LOADING";

export const GET_MOVIE_PART_LOADING = "GET_MOVIE_PART_LOADING";
export const GET_MOVIE_PART_SUCCESS = "GET_MOVIE_PART_SUCCESS";
export const GET_MOVIE_PART_FAIL = "GET_MOVIE_PART_FAIL";

export const STORE_MOVIE_PART_LOADING = "STORE_MOVIE_PART_LOADING";
export const STORE_MOVIE_PART_SUCCESS = "STORE_MOVIE_PART_SUCCESS";
export const STORE_MOVIE_PART_FAIL = "STORE_MOVIE_PART_FAIL";

export const UPDATE_MOVIE_PART_LOADING = "UPDATE_MOVIE_PART_LOADING";
export const UPDATE_MOVIE_PART_SUCCESS = "UPDATE_MOVIE_PART_SUCCESS";
export const UPDATE_MOVIE_PART_FAIL = "UPDATE_MOVIE_PART_FAIL";

export const DELETE_MOVIE_PART_LOADING = "DELETE_MOVIE_PART_LOADING";
export const DELETE_MOVIE_PART_SUCCESS = "DELETE_MOVIE_PART_SUCCESS";
export const DELETE_MOVIE_PART_FAIL = "DELETE_MOVIE_PART_FAIL";

export const MOVIE_PART_UPLOAD_LOADING = "MOVIE_PART_UPLOAD_LOADING";

export const GET_CLIENTS_LOADING = "GET_CLIENTS_LOADING";
export const GET_CLIENTS_SUCCESS = "GET_CLIENTS_SUCCESS";
export const GET_CLIENTS_FAIL = "GET_CLIENTS_FAIL";

export const GET_ADMINS_LOADING = "GET_ADMINS_LOADING";
export const GET_ADMINS_SUCCESS = "GET_ADMINS_SUCCESS";
export const GET_ADMINS_FAIL = "GET_ADMINS_FAIL";

export const GET_USER_LOADING = "GET_USER_LOADING";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAIL = "GET_USER_FAIL";

export const STORE_USER_LOADING = "STORE_USER_LOADING";
export const STORE_USER_SUCCESS = "STORE_USER_SUCCESS";
export const STORE_USER_FAIL = "STORE_USER_FAIL";

export const UPDATE_USER_LOADING = "UPDATE_USER_LOADING";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

export const DELETE_USER_LOADING = "DELETE_USER_LOADING";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";
