import {
  GET_MOVIES_LOADING,
  GET_MOVIES_SUCCESS,
  GET_MOVIES_FAIL,
  STORE_MOVIE_LOADING,
  GET_MOVIE_LOADING,
  UPDATE_MOVIE_LOADING,
  DELETE_MOVIE_LOADING,
  STORE_MOVIE_SUCCESS,
  STORE_MOVIE_FAIL,
  UPDATE_MOVIE_SUCCESS,
  UPDATE_MOVIE_FAIL,
  DELETE_MOVIE_SUCCESS,
  DELETE_MOVIE_FAIL,
  GET_MOVIE_SUCCESS,
  GET_MOVIE_FAIL,
  MOVIE_UPLOAD_LOADING,
  GET_MOVIE_PART_LOADING,
  GET_MOVIE_PART_SUCCESS,
  GET_MOVIE_PART_FAIL,
  STORE_MOVIE_PART_LOADING,
  STORE_MOVIE_PART_SUCCESS,
  STORE_MOVIE_PART_FAIL,
  UPDATE_MOVIE_PART_LOADING,
  UPDATE_MOVIE_PART_SUCCESS,
  UPDATE_MOVIE_PART_FAIL,
  DELETE_MOVIE_PART_LOADING,
  DELETE_MOVIE_PART_SUCCESS,
  DELETE_MOVIE_PART_FAIL,
  MOVIE_PART_UPLOAD_LOADING,
} from "../actions/types";

const initialState = {
  isLoading: false,
  movies: [],
  movie: {},
  part: {},
  uploadPercent: 0,
  uploadPartPercent: 0,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case MOVIE_UPLOAD_LOADING:
      return {
        ...state,
        uploadPercent: action.payload,
      };
    case MOVIE_PART_UPLOAD_LOADING:
      return {
        ...state,
        uploadPartPercent: action.payload,
      };
    case GET_MOVIES_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_MOVIES_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        movies: action.payload,
      };
    case GET_MOVIES_FAIL:
      return {
        ...state,
        isLoading: false,
        movies: [],
      };
    case GET_MOVIE_LOADING:
    case STORE_MOVIE_LOADING:
    case UPDATE_MOVIE_LOADING:
    case DELETE_MOVIE_LOADING:
    case GET_MOVIE_PART_LOADING:
    case STORE_MOVIE_PART_LOADING:
    case UPDATE_MOVIE_PART_LOADING:
    case DELETE_MOVIE_PART_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case STORE_MOVIE_SUCCESS:
    case STORE_MOVIE_FAIL:
    case STORE_MOVIE_PART_SUCCESS:
    case STORE_MOVIE_PART_FAIL:
    case UPDATE_MOVIE_SUCCESS:
    case UPDATE_MOVIE_FAIL:
    case UPDATE_MOVIE_PART_SUCCESS:
    case UPDATE_MOVIE_PART_FAIL:
    case DELETE_MOVIE_SUCCESS:
    case DELETE_MOVIE_FAIL:
    case DELETE_MOVIE_PART_SUCCESS:
    case DELETE_MOVIE_PART_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case GET_MOVIE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        movie: action.payload,
      };
    case GET_MOVIE_FAIL:
      return {
        ...state,
        isLoading: false,
        movie: {},
      };
    case GET_MOVIE_PART_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        part: action.payload,
      };
    case GET_MOVIE_PART_FAIL:
      return {
        ...state,
        isLoading: false,
        part: {},
      };
    default:
      return state;
  }
}
