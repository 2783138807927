import React, { Component } from "react";
import AUX from "../../hoc/Aux_";
import { Link } from "react-router-dom";
import axios from "axios";
import { moment } from "fullcalendar";
import SweetAlert from "react-bootstrap-sweetalert/lib/dist/SweetAlert";
import {
  getCategory,
  updateCategory
} from "../../store/actions/categoryActions";
import { connect } from "react-redux";

class CategoryEdit extends Component {
  state = {
    id: "",
    name: "",
    order: "",
    display_type: "",
    alert: null
  };
  componentDidMount() {
    this.props
      .getCategory({ id: this.props.match.params.categoryId })
      .then(() =>
        this.setState({
          id: this.props.category.id,
          name: this.props.category.name,
          order: this.props.category.order,
          display_type: this.props.category.display_type
        })
      );
  }
  handleSweetAlert(type) {
    const successAlert = () => (
      <SweetAlert
        success
        title=""
        onConfirm={() => this.props.history.push("/categories")}
      >
        Information enregistrée avec succès !
      </SweetAlert>
    );
    const errorAlert = () => (
      <SweetAlert error title="" onConfirm={() => this.hideAlert()}>
        Oups, une erreur a été détecté.
      </SweetAlert>
    );
    this.setState({
      alert: type === "success" ? successAlert() : errorAlert()
    });
  }
  hideAlert() {
    this.setState({ alert: null });
  }
  handleChangeName = e => {
    if (e.target.type === "file") {
      this.setState({ [e.target.name]: e.target.files[0] });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };
  handleSubmit = e => {
    e.preventDefault();
    const { id, name, order, display_type } = this.state;
    const category = {
      name,
      order,
      display_type
    };
    this.props
      .updateCategory(id, category)
      .then(() => this.handleSweetAlert("success"))
      .catch(() => this.handleSweetAlert("error"));
  };
  render() {
    const { alert, name, display_type, order } = this.state;
    const { isLoading } = this.props;
    return (
      <AUX>
        {alert}
        <div className="row">
          <div className="col-sm-12">
            <div className="page-title-box">
              <h4 className="page-title">Modification catégorie</h4>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="javascript:void(0);">Tableau de bord</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="javascript:void(0);">Catégories</Link>
                </li>
                <li className="breadcrumb-item active">
                  Modification catégorie
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card m-b-20">
              <div className="card-body">
                {isLoading ? (
                  <div className="alert alert-info text-center">
                    Chargement en cours ...
                  </div>
                ) : (
                  <form onSubmit={this.handleSubmit}>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Nom</label>
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            value={name}
                            onChange={this.handleChangeName}
                            placeholder=""
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Type d'affichage *</label>
                          <select
                            name="display_type"
                            value={display_type}
                            onChange={this.handleChangeName}
                            className="form-control"
                            required
                          >
                            <option value="">--</option>
                            <option value="XL">Bannière (XL)</option>
                            <option value="SM">Petit (SM)</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <label>Ordre d'affichage</label>
                          <input
                            type="number"
                            className="form-control"
                            name="order"
                            value={order}
                            onChange={this.handleChangeName}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <button
                          type="submit"
                          className="btn btn-warning waves-effect waves-light"
                        >
                          ENREGISTRER
                        </button>
                        &nbsp;
                        <button
                          type="reset"
                          className="btn btn-secondary waves-effect m-l-5"
                        >
                          ANNULER
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoading: state.category.isLoading,
    category: state.category.category
  };
};

const mapDispatchToProps = {
  getCategory,
  updateCategory
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryEdit);
