import React, { Component } from "react";
import AUX from "../../hoc/Aux_";
import { connect } from "react-redux";
import { register } from "../../store/actions/authActions";
import { updateLogin, updateLoginAgain } from "../../store/actions/uiActions";
import { clearErrors } from "../../store/actions/errorActions";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

class Register extends Component {
  state = {
    name: "",
    email: "",
    phone_numbers: "",
    password: "",
    msg: null
  };
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    error: PropTypes.object.isRequired,
    register: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired
  };
  componentDidMount() {
    // if (this.props.loginpage === false) {
    //   this.props.updateLogin();
    // }
    // window.onpopstate = e => {
    //   this.props.updateLoginAgain();
    // };
    if (this.props.isAuthenticated) {
      this.props.history.push("/");
    }
  }
  componentDidUpdate(prevProps) {
    const { error, isAuthenticated } = this.props;
    if (error !== prevProps.error) {
      // Check for register error
      if (error.id === "REGISTER_FAIL") {
        this.setState({ msg: error.msg.message });
      } else {
        this.setState({ msg: null });
      }
    }
    if (isAuthenticated) {
      this.props.history.push("/");
    }
  }
  componentWillUnmount() {
    this.props.clearErrors();
  }
  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onSubmit = e => {
    e.preventDefault();
    const { name, email, phone_numbers, password } = this.state;

    // // Create user object
    const newUser = { name, email, phone_numbers, password };

    // // Attempt to register
    this.props.register(newUser);
  };
  render() {
    return (
      <AUX>
        <div className="wrapper-page">
          <div className="card">
            <div className="card-body">
              <h3 className="text-center m-0">
                <Link to="/" className="logo logo-admin">
                  <img src="assets/images/logo.png" height="30" alt="logo" />
                </Link>
              </h3>

              <div className="p-3">
                <form
                  className="form-horizontal m-t-30"
                  onSubmit={this.onSubmit}
                >
                  {this.state.msg ? (
                    <div className="alert alert-danger" role="alert">
                      <strong>Oh snap!</strong> {this.state.msg}
                    </div>
                  ) : null}

                  <div className="form-group">
                    <label htmlFor="name">Nom</label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      id="name"
                      placeholder=""
                      onChange={this.onChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="phone_numbers">Contacts</label>
                    <input
                      type="text"
                      className="form-control"
                      name="phone_numbers"
                      id="phone_numbers"
                      placeholder=""
                      onChange={this.onChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      placeholder=""
                      onChange={this.onChange}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="password">Mot de passe</label>
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      name="password"
                      placeholder=""
                      onChange={this.onChange}
                    />
                  </div>

                  <div className="form-group row m-t-20">
                    <div className="col-12 text-right">
                      <button
                        className="btn btn-primary w-md waves-effect waves-light"
                        type="submit"
                      >
                        Register
                      </button>
                    </div>
                  </div>

                  <div className="form-group m-t-10 mb-0 row">
                    <div className="col-12 m-t-20">
                      <p className="font-14 text-muted mb-0">
                        By registering you agree to the Lexa{" "}
                        <a href="#" className="text-primary">
                          Terms of Use
                        </a>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="m-t-40 text-center">
            <p>
              Already have an account ?{" "}
              <Link to="login" className="text-primary">
                {" "}
                Login{" "}
              </Link>{" "}
            </p>
            <p>
              © {new Date().getFullYear()} Lexa. Crafted with{" "}
              <i className="mdi mdi-heart text-danger"></i> by Themesbrand
            </p>
          </div>
        </div>
      </AUX>
    );
  }
}

const mapStatetoProps = state => {
  return {
    loginpage: state.ui_red.loginpage,
    isAuthenticated: state.auth.isAuthenticated,
    error: state.error
  };
};

const mapDispatchtoProps = {
  updateLogin,
  updateLoginAgain,
  register,
  clearErrors
};

export default connect(mapStatetoProps, mapDispatchtoProps)(Register);
