import {
  GET_CATEGORIES_LOADING,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAIL,
  DELETE_CATEGORY_LOADING,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAIL,
  STORE_CATEGORY_LOADING,
  STORE_CATEGORY_SUCCESS,
  STORE_CATEGORY_FAIL,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAIL,
  UPDATE_CATEGORY_LOADING,
  GET_CATEGORY_LOADING,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_FAIL,
  DETACH_CATEGORY_MOVIE_LOADING,
  DETACH_CATEGORY_MOVIE_SUCCESS,
  DETACH_CATEGORY_MOVIE_FAIL,
  ATTACH_CATEGORY_MOVIE_LOADING,
  ATTACH_CATEGORY_MOVIE_SUCCESS,
  ATTACH_CATEGORY_MOVIE_FAIL
} from "../actions/types";

const initialState = {
  isLoading: false,
  categories: [],
  category: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_CATEGORIES_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        categories: action.payload
      };
    case GET_CATEGORIES_FAIL:
      return {
        ...state,
        isLoading: false,
        categories: []
      };
    case STORE_CATEGORY_LOADING:
    case GET_CATEGORY_LOADING:
    case UPDATE_CATEGORY_LOADING:
    case DELETE_CATEGORY_LOADING:
    case ATTACH_CATEGORY_MOVIE_LOADING:
    case DETACH_CATEGORY_MOVIE_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case STORE_CATEGORY_SUCCESS:
    case STORE_CATEGORY_FAIL:
    case UPDATE_CATEGORY_SUCCESS:
    case UPDATE_CATEGORY_FAIL:
    case ATTACH_CATEGORY_MOVIE_SUCCESS:
    case ATTACH_CATEGORY_MOVIE_FAIL:
    case DETACH_CATEGORY_MOVIE_SUCCESS:
    case DETACH_CATEGORY_MOVIE_FAIL:
    case DELETE_CATEGORY_SUCCESS:
    case DELETE_CATEGORY_FAIL:
      return {
        ...state,
        isLoading: false
      };
    case GET_CATEGORY_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        category: action.payload
      };
    case GET_CATEGORY_FAIL:
      return {
        ...state,
        isLoading: false,
        category: {}
      };
    default:
      return state;
  }
}
