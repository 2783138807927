import axios from "axios";
import { Redirect } from "react-router-dom";
import { returnErrors } from "./errorActions";

import {
  USER_LOADING,
  USER_LOADED,
  AUTH_ERROR,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGINPAGE,
  LOGOUT_SUCCESS,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  TOP_BAR,
  SIDE_BAR,
  FOOTER
} from "./types";

// Check token && Load User
export const loadUser = () => (dispatch, getState) => {
  // User loading
  dispatch({ type: USER_LOADING });
  return new Promise((resolve, reject) => {
    axios
      .get("https://itv-lm.paraclet-bk.com/api/user", tokenConfig(getState))
      .then(response => {
        dispatch({ type: USER_LOADED, payload: response.data });
        resolve(response.data);
      })
      .catch(error => {
        dispatch({ type: LOGINPAGE });
        dispatch(
          error.response
            ? returnErrors(error.response.data, error.response.status)
            : returnErrors(error)
        );
        // dispatch(returnErrors(error.response.data, error.response.status));
        dispatch({ type: AUTH_ERROR });
        // reject(error.response.data);
        reject(error.response ? error.response.data : error);
      });
  });
};

// Login User
export const login = ({ email, password }) => dispatch => {
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  // Request Body
  const body = JSON.stringify({ email, password });
  axios
    .post("https://itv-lm.paraclet-bk.com/api/login", body, config)
    .then(res => {
      dispatch({ type: LOGIN_SUCCESS, payload: res.data });
      dispatch({ type: LOGINPAGE });
    })
    .catch(err => {
      dispatch(
        // returnErrors(err.response.data, err.response.status, "LOGIN_FAIL")
        returnErrors("LOGIN_FAIL")
      );
      dispatch(
        err.response
          ? returnErrors(err.response.data, err.response.status)
          : returnErrors(err)
      );
      dispatch({ type: LOGIN_FAIL });
    });
};

// Register User
export const register = ({
  name,
  email,
  password,
  phone_numbers
}) => dispatch => {
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  // Request Body
  const body = JSON.stringify({
    name,
    email,
    phone_numbers,
    password,
    role: "USER"
  });
  axios
    .post("https://itv-lm.paraclet-bk.com/api/register", body, config)
    .then(res => {
      dispatch({ type: REGISTER_SUCCESS, payload: res.data });
      dispatch({ type: LOGINPAGE, value: false });
    })
    .catch(err => {
      dispatch(
        returnErrors(err.response.data, err.response.status, "REGISTER_FAIL")
      );
      dispatch({ type: REGISTER_FAIL });
    });
};

// Logout User
export const logout = () => dispatch => {
  dispatch({ type: LOGOUT_SUCCESS });
  // return {
  //   type: LOGOUT_SUCCESS
  // };
};

export const tokenConfig = getState => {
  // Get token from localStorage
  const token = getState().auth.token;

  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  if (token) {
    config.headers["Authorization"] = "Bearer " + token;
  }

  return config;
};
