import React, { Component } from "react";
import AUX from "../../hoc/Aux_";
import Select from "react-select";
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert/lib/dist/SweetAlert";
import { connect } from "react-redux";
import { storeMovie } from "../../store/actions/movieActions";
import { getCategories } from "../../store/actions/categoryActions";
class MovieAdd extends Component {
  state = {
    alert: null,
    isLoading: false,
    name: "",
    description: "",
    path: "",
    coverSM: "",
    coverMD: "",
    onSlider: false,
    type: "",
    categories: [],
    selectedCategories: null,
    errors: {
      name: "",
      description: "",
      path: "",
      coverSM: "",
      coverMD: "",
      onSlider: "",
      type: "",
      selectedCategories: "",
    },
    isSubmit: false,
  };
  componentDidMount() {
    this.setState({
      isLoading: true,
    });
    this.props
      .getCategories()
      .then(() => {
        let cats = this.props.categories.map((category) => {
          return {
            value: category.id,
            label: category.name,
          };
        });
        this.setState({
          categories: cats,
          isLoading: false,
        });
      })
      .catch(() =>
        this.setState({
          isLoading: false,
        })
      );
  }
  validator(name, value, type, files, checked) {
    if (type === "file") {
      let errors = this.state.errors;
      switch (name) {
        case "coverSM":
          if (files.length > 0) {
            errors.coverSM =
              files[0].size > 300000
                ? "Le poids de l'image de couverture doit être inférieur à 300Ko."
                : "";
          } else {
            errors.coverSM = "Veuillez télécharger l'image de couverture svp.";
          }
          break;
        case "coverMD":
          if (files.length > 0) {
            errors.coverMD =
              files[0].size > 300000
                ? "Le poids de l'image de couverture doit être inférieur à 300Ko."
                : "";
          } else {
            errors.coverMD = "Veuillez télécharger l'image de couverture svp.";
          }
          break;
        case "path":
          if (files.length > 0) {
            errors.path =
              files[0].type !== "video/mp4"
                ? "Le format de movie accepté est le MP4."
                : "";
          } else {
            errors.path = "Veuillez télécharger la movie svp.";
          }
          break;
        default:
          break;
      }
    } else {
      let errors = this.state.errors;
      switch (name) {
        case "name":
          errors.name =
            value.length < 5
              ? "Le titre doit contenir 5 caractères minimum !"
              : "";
          break;
        case "description":
          errors.description =
            value.length < 10
              ? "La description doit contenir 10 caractères minimum !"
              : "";
          break;
        case "onSlider":
          errors.onSlider =
            checked !== true && checked !== false
              ? "Vérifiez avoir bien rempli ce champ svp !"
              : "";
          break;
        case "type":
          errors.type =
            value.length !== 5
              ? "Vérifiez avoir bien rempli ce champ svp !"
              : "";
          break;
        default:
          break;
      }
    }
  }
  handleInputChange = (e) => {
    const { name, value, type, files, checked } = e.target;
    let errors = this.state.errors;
    if (type === "file") {
      this.validator(name, value, type, files);
      this.setState({ errors, [name]: files[0] }, () => {
        // console.log(errors);
      });
    } else if (type === "checkbox") {
      this.validator(name, value, type, files, checked);
      this.setState({ errors, [name]: checked }, () => {
        // console.log(errors);
      });
    } else {
      this.validator(name, value, type, files);
      this.setState({ errors, [name]: value }, () => {
        // console.log(errors);
      });
    }
  };
  handleSelectChange = (selectedOption) => {
    let errors = this.state.errors;
    errors.selectedCategories =
      selectedOption === null
        ? "Choisissez au moins une catégorie de vidéo !"
        : "";
    this.setState({ selectedCategories: selectedOption });
  };
  handleSweetAlert(type, text) {
    const successAlert = () => (
      <SweetAlert
        success
        title=""
        onConfirm={() => this.props.history.push("/movies")}
      >
        {text !== null ? text : "Information enregistrée avec succès !"}
      </SweetAlert>
    );
    const errorAlert = () => (
      <SweetAlert error title="" onConfirm={() => this.hideAlert()}>
        {text !== null ? text : "Oups, une erreur a été détecté !"}
      </SweetAlert>
    );
    this.setState({
      alert: type === "success" ? successAlert() : errorAlert(),
    });
  }
  hideAlert() {
    this.setState({ alert: null });
  }
  handleSubmit = (e) => {
    e.preventDefault();
    const {
      name,
      description,
      path,
      coverSM,
      coverMD,
      onSlider,
      type,
      selectedCategories,
    } = this.state;
    if (
      name === "" ||
      description === "" ||
      path === "" ||
      coverSM === "" ||
      coverMD === "" ||
      onSlider === "" ||
      type === "" ||
      selectedCategories.length === 0
    ) {
      return this.handleSweetAlert(
        "error",
        "Veuillez correctement remplir le formulaire svp."
      );
    }
    this.setState({
      isLoading: true,
      isSubmit: true,
    });
    let selectedCats = "";
    selectedCategories.forEach((element, key) => {
      if (key === 0) {
        selectedCats += element.value;
      } else {
        selectedCats += "||" + element.value;
      }
    });
    const movieForm = new FormData();
    movieForm.append("name", name);
    movieForm.append("description", description);
    movieForm.append("path", path);
    movieForm.append("coverSM", coverSM);
    movieForm.append("coverMD", coverMD);
    movieForm.append("onSlider", onSlider === true ? 1 : 0);
    movieForm.append("type", type);
    movieForm.append("categories", selectedCats);
    this.props
      .storeMovie(movieForm)
      .then(() => {
        this.setState({
          isLoading: false,
          isSubmit: false,
        });
        this.handleSweetAlert("success");
      })
      .catch(() => {
        this.setState({
          isLoading: false,
          isSubmit: false,
        });
        this.handleSweetAlert("error");
      });
  };
  render() {
    const {
      name,
      description,
      onSlider,
      type,

      alert,
      isLoading,
      errors,
      categories,
      selectedCategories,
      isSubmit,
    } = this.state;
    return (
      <AUX>
        {alert}
        <div className="row">
          <div className="col-sm-12">
            <div className="page-title-box">
              <h4 className="page-title">Nouvelle vidéo</h4>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Tableau de bord</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/movies">Vidéos</Link>
                </li>
                <li className="breadcrumb-item active">Nouvelle vidéo</li>
              </ol>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card m-b-20">
              <div className="card-body">
                {isLoading ? (
                  <div>
                    <div className="alert alert-info text-center">
                      Chargement en cours ...
                    </div>
                    {isSubmit && (
                      <div className="progress" style={{ height: "20px" }}>
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: this.props.uploadPercent + "%" }}
                          aria-valuenow={this.props.uploadPercent}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          {this.props.uploadPercent + "%"}
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <form onSubmit={this.handleSubmit} noValidate>
                    <div className="row">
                      <div className="col-md-8">
                        <div className="form-group">
                          <label>Titre</label>
                          <input
                            type="text"
                            name="name"
                            className={
                              errors.name.length > 0
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            value={name}
                            onChange={this.handleInputChange}
                            noValidate
                            // required
                          />
                          {errors.name.length > 0 && (
                            <span className="invalid-feedback">
                              {errors.name}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Image (Image - Petit Format)</label>
                          <input
                            name="coverSM"
                            id="coverSM"
                            type="file"
                            className={
                              errors.coverSM.length > 0
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            onChange={this.handleInputChange}
                            noValidate
                            // required
                          />
                          {errors.coverSM.length > 0 && (
                            <span className="invalid-feedback">
                              {errors.coverSM}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Bannière (Image - Grand Format)</label>
                          <input
                            name="coverMD"
                            id="coverMD"
                            type="file"
                            className={
                              errors.coverMD.length > 0
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            onChange={this.handleInputChange}
                            noValidate
                            // required
                          />
                          {errors.coverMD.length > 0 && (
                            <span className="invalid-feedback">
                              {errors.coverMD}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <label>Ajouter au slider ?</label>
                          <br />
                          <input
                            type="checkbox"
                            name="onSlider"
                            id="onSlider"
                            switch="none"
                            className={
                              errors.onSlider.length > 0
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            checked={onSlider}
                            onChange={this.handleInputChange}
                            noValidate
                          />
                          <label
                            htmlFor="onSlider"
                            data-on-label="Oui"
                            data-off-label="Non"
                          ></label>
                          {errors.onSlider.length > 0 && (
                            <span className="invalid-feedback">
                              {errors.onSlider}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <label>Type de vidéo</label>
                          <br />
                          <select
                            name="type"
                            id="type"
                            className={
                              errors.type.length > 0
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            onChange={this.handleInputChange}
                            noValidate
                          >
                            <option value="">--</option>
                            <option value="MOVIE">FILM</option>
                            <option value="SERIE">SERIE</option>
                          </select>
                          {errors.type.length > 0 && (
                            <span className="invalid-feedback">
                              {errors.type}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Description</label>
                          <textarea
                            name="description"
                            className={
                              errors.description.length > 0
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            cols="30"
                            rows="10"
                            value={description}
                            onChange={this.handleInputChange}
                            noValidate
                            // required
                          ></textarea>
                          {errors.description.length > 0 && (
                            <span className="invalid-feedback">
                              {errors.description}
                            </span>
                          )}
                        </div>
                      </div>
                      {type === "MOVIE" ? (
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Film</label>
                            <input
                              name="path"
                              id="path"
                              type="file"
                              className={
                                errors.path.length > 0
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              onChange={this.handleInputChange}
                              noValidate
                              // required
                            />
                            {errors.path.length > 0 && (
                              <span className="invalid-feedback">
                                {errors.description}
                              </span>
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="">Catégories</label>
                          <Select
                            name="selectedCategories"
                            placeholder="--"
                            value={selectedCategories}
                            onChange={this.handleSelectChange}
                            options={categories}
                            isClearable={true}
                            isMulti={true}
                          />
                          {errors.selectedCategories.length > 0 && (
                            <span className="text-danger">
                              {errors.selectedCategories}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <button
                        type="submit"
                        className="btn btn-primary waves-effect waves-light"
                      >
                        ENVOYER
                      </button>
                      &nbsp;
                      <button
                        type="reset"
                        className="btn btn-secondary waves-effect waves-light"
                      >
                        ANNULER
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categories: state.category.categories,
    uploadPercent: state.movie.uploadPercent,
  };
};

const mapDispatchToProps = {
  storeMovie,
  getCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(MovieAdd);
