import React, { Component } from "react";
import AUX from "../../hoc/Aux_";
import { Link } from "react-router-dom";
import Axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert/lib/dist/SweetAlert";
import { getUser } from "../../store/actions/userActions";
import { connect } from "react-redux";
import moment from "moment";

class UserDetails extends Component {
  state = {
    isLoading: false,
    alert: null,
  };
  componentDidMount() {
    this.handleUser();
  }
  handleUser() {
    this.setState({ isLoading: true });
    let userId = this.props.match.params.userId;
    this.props
      .getUser(userId)
      .then(() => {
        this.setState({
          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          isLoading: false,
        });
      });
  }
  handleDeleteUser(categoryId) {
    this.setState({
      isLoading: true,
    });
    Axios.delete("https://itv-lm.paraclet-bk.com/api/users/" + categoryId)
      .then(() => {
        this.handleSuccess();
        this.setState({ isLoading: false });
        this.handleSuccess();
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          isLoading: false,
        });
      });
  }
  handleConfirm(userId) {
    const WarningText = () => (
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Oui"
        cancelBtnText="Non"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        title=""
        onCancel={() => this.hideAlert()}
        onConfirm={() => this.handleDeleteUser(userId)}
      >
        Êtes-vous sûre de vouloir supprimer cet utilisateur ?
      </SweetAlert>
    );
    this.setState({ alert: WarningText() });
  }
  handleSuccess() {
    const SuccessText = () => (
      <SweetAlert success title="" onConfirm={() => this.hideAlert()}>
        Catégorie supprimé avec succès !
      </SweetAlert>
    );
    this.setState({ alert: SuccessText() });
  }
  hideAlert() {
    this.setState({ alert: null });
  }
  render() {
    const { isLoading, alert } = this.state;
    const { user } = this.props;
    return (
      <AUX>
        {alert}
        <div className="row">
          <div className="col-sm-12">
            <div className="page-title-box">
              <h4 className="page-title">Comptes</h4>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Tableau de bord</Link>
                </li>
                <li className="breadcrumb-item">
                  <a href="#" onClick={() => this.props.history.goBack()}>
                    {user && user.role === "ADMIN"
                      ? "Administrateurs"
                      : "Abonnées"}
                  </a>
                </li>
                <li className="breadcrumb-item active">Détails</li>
              </ol>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card m-b-20">
              <div className="card-body">
                {isLoading ? (
                  <div className="alert alert-info text-center">
                    Chargement en cours ...
                  </div>
                ) : (
                  <div>
                    <ul
                      className="nav nav-pills mb-3"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          id="pills-home-tab"
                          data-toggle="pill"
                          href="#pills-home"
                          role="tab"
                          aria-controls="pills-home"
                          aria-selected="true"
                        >
                          Infos client
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="pills-profile-tab"
                          data-toggle="pill"
                          href="#pills-profile"
                          role="tab"
                          aria-controls="pills-profile"
                          aria-selected="false"
                        >
                          Historique & Favoris
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="pills-home"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                      >
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label>Nom</label>
                              <input
                                type="text"
                                name="name"
                                value={user.name}
                                className="form-control"
                                disabled
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Email</label>
                              <input
                                type="text"
                                name="name"
                                value={user.email}
                                className="form-control"
                                disabled
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Contact</label>
                              <input
                                type="text"
                                name="phone_numbers"
                                value={user.phone_numbers}
                                className="form-control"
                                disabled
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            {user.role === "ADMIN" ? (
                              <div className="form-group">
                                <label>Rôle</label>
                                <input
                                  type="text"
                                  name="name"
                                  value="Administrateur"
                                  className="form-control"
                                  disabled
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          {user.role === "ADMIN" ? (
                            <div className="col-md-12">
                              <Link
                                to={"/edit-user/" + user.id}
                                className="btn btn-warning waves-effect waves-light"
                              >
                                <i className="fa fa-edit"></i> MODIFIER
                              </Link>{" "}
                              <button
                                onClick={() => this.handleConfirm(user.id)}
                                className="btn btn-danger waves-effect waves-light"
                              >
                                <i className="fa fa-trash"></i> SUPPRIMER
                              </button>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      {user.role === "USER" ? (
                        <div
                          className="tab-pane fade"
                          id="pills-profile"
                          role="tabpanel"
                          aria-labelledby="pills-profile-tab"
                        >
                          <div className="row">
                            <div className="col-md-12">
                              {user.movies && user.movies.length === 0 && (
                                <div className="alert alert-info text-center">
                                  Aucun contenu disponible !
                                </div>
                              )}
                              {user.movies && user.movies.length > 0 && (
                                <table className="table table-sm table-bordered table-hover">
                                  <thead>
                                    <tr>
                                      <th>#</th>
                                      <th>Video</th>
                                      <th>Date</th>
                                      <th>Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {user.movies.map((movie, key) => (
                                      <tr>
                                        <td>{key + 1}</td>
                                        <td>{movie.name}</td>
                                        <td>
                                          {moment(movie.created_at).format(
                                            "DD/MM/YYYY hh:mm:ss"
                                          )}
                                        </td>
                                        <td>
                                          <Link
                                            to={"/details-movie/" + movie.id}
                                            className="btn btn-info btn-sm"
                                          >
                                            <i className="fa fa-eye"></i>
                                          </Link>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              )}
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    isLoading: state.user.isLoading,
  };
};

const mapDispatchToProps = {
  getUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);
