import {
  GET_CLIENTS_LOADING,
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_FAIL,
  GET_USER_LOADING,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
} from "../actions/types";

const initialState = {
  isLoading: false,
  clients: [],
  user: {},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_CLIENTS_LOADING:
    case GET_USER_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_CLIENTS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        clients: action.payload,
      };
    case GET_CLIENTS_FAIL:
      return {
        ...state,
        isLoading: false,
        clients: [],
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        user: action.payload,
      };
    case GET_USER_FAIL:
      return {
        ...state,
        isLoading: false,
        user: {},
      };
    default:
      return state;
  }
}
