import axios from "axios";
import { returnErrors } from "./errorActions";

import {
  GET_CLIENTS_LOADING,
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_FAIL,
  GET_USER_LOADING,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
} from "./types";

export const getClients = () => (dispatch, getState) => {
  dispatch({ type: GET_CLIENTS_LOADING });
  return new Promise((resolve, reject) => {
    axios
      .get(
        "https://itv-lm.paraclet-bk.com/api/users?role=USER",
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({ type: GET_CLIENTS_SUCCESS, payload: res.data });
        resolve(res.data);
      })
      .catch((err) => {
        dispatch(
          err.response
            ? returnErrors(err.response.data, err.response.status)
            : returnErrors(err)
        );
        dispatch({ type: GET_CLIENTS_FAIL });
        reject(err);
      });
  });
};

export const getUser = (userId) => (dispatch, getState) => {
  dispatch({ type: GET_USER_LOADING });
  return new Promise((resolve, reject) => {
    axios
      .get(
        "https://itv-lm.paraclet-bk.com/api/users/" + userId,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({ type: GET_USER_SUCCESS, payload: res.data });
        resolve(res.data);
      })
      .catch((err) => {
        dispatch(
          err.response
            ? returnErrors(err.response.data, err.response.status)
            : returnErrors(err)
        );
        dispatch({ type: GET_USER_FAIL });
        reject(err);
      });
  });
};

export const tokenConfig = (getState) => {
  // Get token from localStorage
  const token = getState().auth.token;

  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  if (token) {
    config.headers["Authorization"] = "Bearer " + token;
  }

  return config;
};
