import React, { Component } from "react";
import AUX from "../../hoc/Aux_";
import { Link } from "react-router-dom";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert/lib/dist/SweetAlert";
import { connect } from "react-redux";
import { getClients } from "../../store/actions/userActions";
import { MDBDataTable } from "mdbreact";
import moment from "moment";

class ClientList extends Component {
  state = {
    users: [],
    isLoading: false,
    alert: null,
    data: [],
  };
  componentDidMount() {
    this.setState({
      isLoading: true,
    });
    this.getClientForDatatables()
      .then(() => {
        this.setState({
          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          isLoading: false,
        });
      });
  }
  getClientForDatatables() {
    return new Promise((resolve, reject) => {
      this.props
        .getClients()
        .then(() => {
          let columns = [
            {
              label: "#",
              field: "key",
              sort: "asc",
            },
            {
              label: "Nom",
              field: "name",
              sort: "asc",
            },
            {
              label: "Adresse email",
              field: "email",
              sort: "asc",
            },
            {
              label: "Rôle",
              field: "role",
              sort: "asc",
            },
            {
              label: "Date d'inscription",
              field: "created_at",
              sort: "asc",
            },
            {
              label: "Actions",
              field: "actions",
            },
          ];
          let rows = this.props.clients.map((client, key) => {
            return {
              key: key + 1,
              name: client.name,
              email: client.email,
              role: client.role,
              created_at: moment(client.created_at).format(
                "DD/MM/YYYY hh:mm:ss"
              ),
              actions: (
                <span>
                  <Link
                    to={"/details-user/" + client.id}
                    className="btn btn-info btn-sm"
                  >
                    <i className="fa fa-eye"></i>
                  </Link>{" "}
                  <button
                    className="btn btn-danger btn-sm waves-effect waves-light"
                    onClick={() => this.handleConfirm(client.id)}
                  >
                    <i className="fa fa-trash"></i>
                  </button>
                </span>
              ),
            };
          });
          let data = {
            columns: columns,
            rows: rows,
          };
          this.setState({
            data: data,
          });
          resolve(this.state.data);
        })
        .catch((err) => {
          let errorMsg = "Erreur lors de la recuperation des categories.";
          reject(err);
        });
    });
  }
  handleDeleteUser(categoryId) {
    this.setState({
      isLoading: true,
    });
    axios
      .delete("https://itv-lm.paraclet-bk.com/api/users/" + categoryId)
      .then(() => {
        this.handleSuccess();
        this.setState({ isLoading: false });
        this.handleUsers();
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          isLoading: false,
        });
      });
  }
  handleConfirm(userId) {
    const WarningText = () => (
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Oui"
        cancelBtnText="Non"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        title=""
        onCancel={() => this.hideAlert()}
        onConfirm={() => this.handleDeleteUser(userId)}
      >
        Êtes-vous sûre de vouloir supprimer cette catégorie ?
      </SweetAlert>
    );
    this.setState({ alert: WarningText() });
  }
  handleSuccess() {
    const SuccessText = () => (
      <SweetAlert success title="" onConfirm={() => this.hideAlert()}>
        Catégorie supprimé avec succès !
      </SweetAlert>
    );
    this.setState({ alert: SuccessText() });
  }
  hideAlert() {
    this.setState({ alert: null });
  }
  render() {
    const { isLoading, alert, data } = this.state;
    return (
      <AUX>
        {alert}
        <div className="row">
          <div className="col-sm-12">
            <div className="page-title-box">
              <h4 className="page-title">Abonnées</h4>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="javascript:void(0);">Tableau de bord</Link>
                </li>
                <li className="breadcrumb-item active">Abonnées</li>
              </ol>
              {/* <div className="state-information d-none d-sm-block">
                <Link to="/add-user" className="btn btn-info">
                  Nouveau compte
                </Link>
              </div> */}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card m-b-20">
              <div className="card-body">
                {isLoading ? (
                  <div className="alert alert-primary text-center">
                    Chargement en cours ...
                  </div>
                ) : (
                  <MDBDataTable data={data} />
                )}
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clients: state.user.clients,
    isLoading: state.user.isLoading,
  };
};

const mapDispatchToProps = {
  getClients,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientList);
