import React, { Component } from "react";
import AUX from "../../hoc/Aux_";
import { Link } from "react-router-dom";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert/lib/dist/SweetAlert";
import { connect } from "react-redux";
import {
  getCategory,
  detachCategoryMovie
} from "../../store/actions/categoryActions";

class CategoryDetails extends Component {
  state = {
    alert: null
  };
  componentDidMount() {
    this.props.getCategory({ id: this.props.match.params.categoryId });
  }
  handleDetachCategoryMovie(categoryId, movieId) {
    let detachData = {
      category_id: categoryId,
      movie_id: movieId
    };
    this.props.detachCategoryMovie(detachData).then(() => {
      this.props.getCategory({ id: this.props.match.params.categoryId });
      this.handleSuccess();
    });
  }
  handleConfirm(categoryId, movieId) {
    const WarningText = () => (
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Oui"
        cancelBtnText="Non"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        title=""
        onCancel={() => this.hideAlert()}
        onConfirm={() => this.handleDetachCategoryMovie(categoryId, movieId)}
      >
        Êtes-vous sûre de vouloir supprimer cette catégorie ?
      </SweetAlert>
    );
    this.setState({ alert: WarningText() });
  }
  handleSuccess() {
    const SuccessText = () => (
      <SweetAlert success title="" onConfirm={() => this.hideAlert()}>
        Catégorie supprimé avec succès !
      </SweetAlert>
    );
    this.setState({ alert: SuccessText() });
  }
  hideAlert() {
    this.setState({ alert: null });
  }
  render() {
    const { alert } = this.state;
    const { isLoading, category } = this.props;
    return (
      <AUX>
        {alert}
        <div className="row">
          <div className="col-sm-12">
            <div className="page-title-box">
              <h4 className="page-title">Détails catégorie</h4>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="javascript:void(0);">Tableau de bord</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="javascript:void(0);">Catégories</Link>
                </li>
                <li className="breadcrumb-item active">Détails catégorie</li>
              </ol>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card m-b-20">
              <div className="card-body">
                {isLoading ? (
                  <div className="alert alert-info text-center">
                    Chargement en cours ...
                  </div>
                ) : (
                  <div>
                    <ul
                      className="nav nav-pills mb-3"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          id="pills-infos-tab"
                          data-toggle="pill"
                          href="#pills-infos"
                          role="tab"
                          aria-controls="pills-infos"
                          aria-selected="true"
                        >
                          Infos sur la catégorie
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="pills-movies-tab"
                          data-toggle="pill"
                          href="#pills-movies"
                          role="tab"
                          aria-controls="pills-movies"
                          aria-selected="false"
                        >
                          Vidéos Assignées
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="pills-infos"
                        role="tabpanel"
                        aria-labelledby="pills-infos-tab"
                      >
                        <form className="pt-2">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Nom</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={category.name}
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>Type d'affichage</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={category.display_type}
                                  readOnly
                                />
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="form-group">
                                <label>Ordre d'affichage</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={category.order}
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <Link
                                to={"/category-edit/" + category.id}
                                className="btn btn-warning waves-effect waves-light"
                              >
                                <i className="ti-pencil-alt"></i>&nbsp; MODIFIER
                              </Link>
                              &nbsp;
                              <button
                                type="button"
                                onClick={() => this.handleConfirm(category.id)}
                                className="btn btn-danger waves-effect m-l-5"
                              >
                                <i className="ti-trash"></i>&nbsp; SUPPRIMER
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="pills-movies"
                        role="tabpanel"
                        aria-labelledby="pills-movies-tab"
                      >
                        {/* <div className="row">
                          <div className="col-md-12">
                            <div className="pull-right">
                              <Link
                                to={"/attach-movie-to-category/" + category.id}
                                className="btn btn-info"
                              >
                                Assigner une vidéo
                              </Link>
                            </div>
                          </div>
                        </div> */}
                        <br />
                        <table className="table table-hover table-bordered">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Nom</th>
                              <th>Description</th>
                              <th>Cover</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {category.movies &&
                              category.movies.map((movie, index) => (
                                <tr key={index}>
                                  <th>{index + 1}</th>
                                  <td style={{ width: "15%" }}>{movie.name}</td>
                                  <td>{movie.description.substring(0, 130)}</td>
                                  <td>
                                    <img
                                      src={
                                        "https://itv-lm.paraclet-bk.com/storage/" +
                                        movie.cover
                                      }
                                      style={{ height: 50, width: null }}
                                      alt=""
                                      className="img-thumbnail"
                                    />
                                  </td>
                                  <td style={{ width: "10%" }}>
                                    <Link
                                      to={"/details-movie/" + movie.id}
                                      className="btn btn-info btn-sm"
                                    >
                                      <i className="fa fa-eye"></i>
                                    </Link>
                                    &nbsp;
                                    <button
                                      className="btn btn-danger btn-sm waves-effect waves-light"
                                      onClick={() =>
                                        this.handleConfirm(
                                          category.id,
                                          movie.id
                                        )
                                      }
                                    >
                                      <i className="fa fa-trash"></i>
                                    </button>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoading: state.category.isLoading,
    category: state.category.category
  };
};

const mapDispatchToProps = {
  getCategory,
  detachCategoryMovie
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryDetails);
