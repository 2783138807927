import React, { Component } from "react";
import AUX from "../../hoc/Aux_";
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert/lib/dist/SweetAlert";
import { connect } from "react-redux";
import {
  getMovie,
  deleteMoviePart,
  deleteMovie,
} from "../../store/actions/movieActions";
import { detachCategoryMovie } from "../../store/actions/categoryActions";

class MovieDetails extends Component {
  state = {
    alert: null,
    isLoading: false,
    selectedMovie: null,
  };
  componentDidMount() {
    this.getMovieData();
  }
  getMovieData() {
    this.setState({
      isLoading: true,
    });
    this.props
      .getMovie({ movieId: this.props.match.params.movieId })
      .then(() => {
        this.setState({
          isLoading: false,
        });
      })
      .catch(() =>
        this.setState({
          isLoading: false,
        })
      );
  }
  handleConfirm(action, text) {
    const WarningText = () => (
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Oui"
        cancelBtnText="Non"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        title=""
        onCancel={() => this.hideAlert()}
        onConfirm={action}
      >
        {text}
      </SweetAlert>
    );
    this.setState({ alert: WarningText() });
  }
  handleSuccess(text) {
    const SuccessText = () => (
      <SweetAlert success title="" onConfirm={() => this.hideAlert()}>
        {text}
      </SweetAlert>
    );
    this.setState({ alert: SuccessText() });
  }
  hideAlert() {
    this.setState({ alert: null });
  }
  handleDeletePart = (partId) => {
    this.setState({
      isLoading: true,
    });
    this.props
      .deleteMoviePart(partId)
      .then(() => {
        this.setState({
          isLoading: false,
          selectedMovie: null,
        });
        this.handleSuccess("Episode supprimé avec succès !");
        this.getMovieData();
      })
      .catch(() => {
        this.setState({
          isLoading: false,
        });
      });
  };
  handleDeleteMovie = (movieId) => {
    this.setState({
      isLoading: true,
    });
    this.props
      .deleteMovie(movieId)
      .then(() => {
        this.handleSuccess("Vidéo supprimé avec succès !");
        this.setState({
          isLoading: false,
        });
        this.props.history.push("/movies");
      })
      .catch(() => {
        this.setState({
          isLoading: false,
        });
      });
  };
  handleDetachCategory = (categoryId) => {
    this.setState({
      isLoading: true,
    });
    let detachData = {
      category_id: categoryId,
      movie_id: this.props.match.params.movieId,
    };
    this.props
      .detachCategoryMovie(detachData)
      .then(() => {
        this.handleSuccess("Catégorie retiré avec succès !");
        this.setState({
          isLoading: false,
        });
        this.getMovieData();
      })
      .catch(() => {
        this.setState({
          isLoading: false,
        });
      });
  };
  displayPartInfo(part) {
    this.setState({
      selectedMovie: part,
    });
  }
  render() {
    const { alert, selectedMovie } = this.state;
    const { isLoading, movie } = this.props;
    return (
      <AUX>
        {alert}
        <div className="row">
          <div className="col-sm-12">
            <div className="page-title-box">
              <h4 className="page-title">Détails vidéo</h4>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Tableau de bord</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/movies">Vidéos</Link>
                </li>
                <li className="breadcrumb-item active">Détails</li>
              </ol>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card m-b-20">
              <div className="card-body">
                {isLoading ? (
                  <div className="alert alert-info text-center">
                    Chargement en cours ...
                  </div>
                ) : (
                  <div>
                    <ul
                      className="nav nav-pills mb-3"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          id="pills-home-tab"
                          data-toggle="pill"
                          href="#pills-home"
                          role="tab"
                          aria-controls="pills-home"
                          aria-selected="true"
                        >
                          Infos
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="pills-profile-tab"
                          data-toggle="pill"
                          href="#pills-profile"
                          role="tab"
                          aria-controls="pills-profile"
                          aria-selected="false"
                        >
                          {movie.type === "MOVIE"
                            ? "Enregistrement du Film"
                            : "Episodes de la Série"}
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="pills-contact-tab"
                          data-toggle="pill"
                          href="#pills-contact"
                          role="tab"
                          aria-controls="pills-contact"
                          aria-selected="false"
                        >
                          Cover
                        </a>
                      </li>
                    </ul>
                    <hr />
                    <div className="tab-content" id="pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="pills-home"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                      >
                        <form className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="font-weight-bold">Titre</label>
                              <br />
                              <label>{movie.name}</label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="font-weight-bold">
                                Type de movie
                              </label>
                              <br />
                              <label className="badge badge-danger p-1">
                                {movie.type === "MOVIE" ? "Film" : "Série TV"}
                              </label>{" "}
                              {Number(movie.onSlider) === 1 ? (
                                <label className="badge badge-danger p-1">
                                  Bannière
                                </label>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label className="font-weight-bold">
                                Description
                              </label>
                              <br />
                              <label>{movie.description}</label>
                            </div>
                          </div>
                          <div className="col-md-12">
                            {movie.categories && movie.categories.length > 0 ? (
                              <div className="table-responsive">
                                <table className="table table-sm table-hover table-bordered">
                                  <thead>
                                    <tr>
                                      <th>#</th>
                                      <th>Nom</th>
                                      <th>Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {movie.categories.map((category, key) => (
                                      <tr key={category.id}>
                                        <th scope="row">{key + 1}</th>
                                        <td>{category.name}</td>
                                        <td>
                                          <button
                                            type="button"
                                            className="btn btn-danger btn-sm waves-effect waves-light"
                                            onClick={() =>
                                              this.handleConfirm(
                                                () =>
                                                  this.handleDetachCategory(
                                                    category.id
                                                  ),
                                                "Êtes-vous sûre de vouloir supprimer cette catégorie ?"
                                              )
                                            }
                                          >
                                            <i className="fa fa-trash"></i>
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            ) : (
                              <div className="alert alert-info text-center">
                                Aucune catégorie assigné à cette vidéo
                              </div>
                            )}
                          </div>
                        </form>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="pills-profile"
                        role="tabpanel"
                        aria-labelledby="pills-profile-tab"
                      >
                        {movie.type === "MOVIE" ? (
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group text-center">
                                {movie.path ? (
                                  <video
                                    controls
                                    width="70%"
                                    height="auto"
                                    controlsList="nodownload"
                                  >
                                    <source src={movie.path} type="video/mp4" />
                                  </video>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="row">
                            <div className="col-md-12 pb-3">
                              <div className="float-right">
                                <button
                                  onClick={() =>
                                    this.props.history.push(
                                      "/movies/" + movie.id + "/add-part"
                                    )
                                  }
                                  className="btn btn-primary btn-sm"
                                >
                                  Ajouter un épisode
                                </button>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <ul className="list-group">
                                <li className="list-group-item active">
                                  Episodes de la série
                                </li>
                                {movie.parts &&
                                  movie.parts.map((part, key) => (
                                    <a
                                      href="javascript:void(0);"
                                      className={
                                        selectedMovie &&
                                        selectedMovie.id === part.id
                                          ? "list-group-item text-primary"
                                          : "list-group-item"
                                      }
                                      key={part.id}
                                      onClick={() => this.displayPartInfo(part)}
                                    >
                                      {selectedMovie &&
                                      selectedMovie.id === part.id ? (
                                        <i className="ti-angle-double-right"></i>
                                      ) : (
                                        ""
                                      )}
                                      {" Episode " +
                                        part.episode +
                                        " - " +
                                        part.name}
                                    </a>
                                  ))}
                                {!movie.parts && (
                                  <li className="list-group-item">
                                    <div className="alert alert-danger text-center">
                                      Aucun épisode
                                    </div>
                                  </li>
                                )}
                              </ul>
                            </div>
                            <div className="col-md-9">
                              {selectedMovie !== null ? (
                                <div className="card m-b-30">
                                  <iframe
                                    title={selectedMovie.name}
                                    className="embed-responsive-item"
                                    allowfullscreen="true"
                                    sandbox=""
                                    src={
                                      "https://itv-bucket-1.s3.eu-west-2.amazonaws.com/" +
                                      selectedMovie.path
                                    }
                                    width="auto"
                                    height="350"
                                  ></iframe>
                                  <div className="card-body">
                                    <h4 className="card-title font-16 mt-0">
                                      {selectedMovie.name}
                                    </h4>
                                    <p className="card-text font-weight-bold">
                                      {"Episode : " + selectedMovie.episode}
                                    </p>
                                    <p className="card-text">
                                      {selectedMovie.description}
                                    </p>
                                    <p className="card-text">
                                      <button
                                        className="btn btn-sm btn-primary"
                                        data-toggle="modal"
                                        data-target="#exampleModalSM"
                                      >
                                        <div className="fa fa-image"></div>{" "}
                                        Apercu Cover SM
                                      </button>{" "}
                                      <div
                                        className="modal fade"
                                        id="exampleModalSM"
                                        tabindex="-1"
                                        role="dialog"
                                        aria-labelledby="exampleModalSMLabel"
                                        aria-hidden="true"
                                      >
                                        <div
                                          className="modal-dialog"
                                          role="document"
                                        >
                                          <div className="modal-content">
                                            <div className="modal-header">
                                              <h5
                                                className="modal-title"
                                                id="exampleModalSMLabel"
                                              >
                                                Cover SM
                                              </h5>
                                              <button
                                                type="button"
                                                className="close"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                              >
                                                <span aria-hidden="true">
                                                  &times;
                                                </span>
                                              </button>
                                            </div>
                                            <div className="modal-body text-center">
                                              <img
                                                src={
                                                  "https://itv-lm.paraclet-bk.com/storage/" +
                                                  selectedMovie.coverSM
                                                }
                                                width="auto"
                                                height="200"
                                                alt=""
                                              />
                                            </div>
                                            <div className="modal-footer">
                                              <button
                                                type="button"
                                                className="btn btn-secondary"
                                                data-dismiss="modal"
                                              >
                                                FERMER
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <button
                                        className="btn btn-sm btn-outline-primary"
                                        data-toggle="modal"
                                        data-target="#exampleModalMD"
                                      >
                                        <div className="fa fa-images"></div>{" "}
                                        Apercu Cover MD
                                      </button>
                                      <div
                                        className="modal fade"
                                        id="exampleModalMD"
                                        tabindex="-1"
                                        role="dialog"
                                        aria-labelledby="exampleModalMDLabel"
                                        aria-hidden="true"
                                      >
                                        <div
                                          className="modal-dialog"
                                          role="document"
                                        >
                                          <div className="modal-content">
                                            <div className="modal-header">
                                              <h5
                                                className="modal-title"
                                                id="exampleModalMDLabel"
                                              >
                                                Cover MD
                                              </h5>
                                              <button
                                                type="button"
                                                className="close"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                              >
                                                <span aria-hidden="true">
                                                  &times;
                                                </span>
                                              </button>
                                            </div>
                                            <div className="modal-body text-center">
                                              <img
                                                src={
                                                  "https://itv-lm.paraclet-bk.com/storage/" +
                                                  selectedMovie.coverMD
                                                }
                                                width="auto"
                                                height="350"
                                                alt=""
                                              />
                                            </div>
                                            <div className="modal-footer">
                                              <button
                                                type="button"
                                                className="btn btn-secondary"
                                                data-dismiss="modal"
                                              >
                                                FERMER
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </p>
                                    <p className="card-text">
                                      <small className="text-muted">
                                        {selectedMovie.created_at}
                                      </small>
                                    </p>
                                    <hr />
                                    <div className="text-center">
                                      <Link
                                        to={"/edit-part/" + selectedMovie.id}
                                        className="btn btn-warning btn-sm"
                                      >
                                        <i className="fa fa-edit"></i> Modifier
                                        l'épisode
                                      </Link>{" "}
                                      <button
                                        className="btn btn-danger btn-sm waves-effect waves-light"
                                        onClick={() =>
                                          this.handleConfirm(
                                            () =>
                                              this.handleDeletePart(
                                                selectedMovie.id
                                              ),
                                            "Êtes-vous sûre de vouloir supprimer cet episode ?"
                                          )
                                        }
                                      >
                                        <i className="fa fa-trash"></i>{" "}
                                        Supprimer l'épisode
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="alert alert-info text-center">
                                  Aucun épisode sélectionné !
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                      <div
                        className="tab-pane fade"
                        id="pills-contact"
                        role="tabpanel"
                        aria-labelledby="pills-contact-tab"
                      >
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group text-center">
                              <label>Image (Image - Petit Format)</label>
                              <br />
                              {movie.coverSM ? (
                                <img
                                  src={
                                    "https://itv-lm.paraclet-bk.com/storage/" +
                                    movie.coverSM
                                  }
                                  style={{ width: "auto", height: "350px" }}
                                  alt=""
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group text-center">
                              <label>Bannière (Image - Grand Moyen)</label>
                              <br />
                              {movie.coverMD ? (
                                <img
                                  src={
                                    "https://itv-lm.paraclet-bk.com/storage/" +
                                    movie.coverMD
                                  }
                                  style={{ width: "auto", height: "350px" }}
                                  alt=""
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row col-md-12 pt-3">
                      <Link
                        to={"/edit-movie/" + movie.id}
                        className="btn btn-warning waves-effect waves-light"
                      >
                        <i className="fa fa-edit"></i> MODIFIER
                      </Link>
                      &nbsp;
                      <button
                        onClick={() =>
                          this.handleConfirm(
                            () => this.handleDeleteMovie(movie.id),
                            "Êtes-vous sûre de vouloir supprimer cette movie ?"
                          )
                        }
                        className="btn btn-danger waves-effect waves-light"
                      >
                        <i className="fa fa-trash"></i> SUPPRIMER
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.movie.isLoading,
    movie: state.movie.movie,
  };
};

const mapDispatchToProps = {
  getMovie,
  deleteMoviePart,
  deleteMovie,
  detachCategoryMovie,
};

export default connect(mapStateToProps, mapDispatchToProps)(MovieDetails);
