import React from "react";
//import classes from './Layout.css';
const footer = props => (
  <footer className="footer">
    © {new Date().getFullYear()} InfraDEV -{" "}
    <span className="d-none d-sm-inline-block">
      {" "}
      Make with <i className="mdi mdi-heart text-danger"></i> by the_itduck
    </span>
    .
  </footer>
);

export default footer;
