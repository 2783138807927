import React, { Component } from "react";
import Layout from "./components/Layout/Layout";
import "./App.css";
import { Route, Switch } from "react-router-dom";
import mainbuilder from "./containers/mainbuilder/mainbuilder";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { loadUser } from "./store/actions/authActions";
import store from "./store";

class App extends Component {
  componentDidMount() {
    this.props
      .loadUser()
      .then(() => {})
      .catch(() => this.props.history.push("/login"));
  }
  render() {
    let layout = null;
    layout = (
      <Layout
        topbar={this.props.topbar}
        sidebar={this.props.sidebar}
        footer={this.props.footer}
        isloginpage={this.props.loginpage}
      >
        <Switch>
          <Route path="/" component={mainbuilder} />
        </Switch>
      </Layout>
    );

    return <div className="App">{layout}</div>;
  }
}
const mapStatetoProps = state => {
  return {
    topbar: state.ui_red.top_bar,
    sidebar: state.ui_red.side_bar,
    loginpage: state.ui_red.loginpage,
    footer: state.ui_red.footer,
    isAuthenticated: state.auth.isAuthenticated
  };
};

const mapDispatchtoProps = {
  loadUser
};

export default withRouter(connect(mapStatetoProps, mapDispatchtoProps)(App));
