import React, { Component } from "react";
import AUX from "../../hoc/Aux_";
import { Link } from "react-router-dom";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from "react-redux";
import {
  getCategories,
  deleteCategory
} from "../../store/actions/categoryActions";
import { MDBDataTable } from "mdbreact";

class CategoriesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      isLoading: false,
      data: {}
    };
  }
  componentDidMount() {
    this.setState({
      isLoading: true
    });
    this.getCategoriesDatatable()
      .then(() => {
        this.setState({
          isLoading: false
        });
      })
      .catch(() => {
        this.setState({
          isLoading: false
        });
      });
  }
  getCategoriesDatatable() {
    return new Promise((resolve, reject) => {
      this.props
        .getCategories()
        .then(() => {
          let columns = [
            {
              label: "#",
              field: "key",
              sort: "asc"
            },
            {
              label: "Nom",
              field: "name",
              sort: "asc"
            },
            {
              label: "Vidéos",
              field: "videoCount",
              sort: "asc"
            },
            {
              label: "Ordre d'affichage",
              field: "order",
              sort: "asc"
            },
            {
              label: "Type d'affichage",
              field: "display_type",
              sort: "asc"
            },
            {
              label: "Actions",
              field: "actions"
            }
          ];
          let rows = this.props.categories.map((category, key) => {
            return {
              key: key + 1,
              name: category.name,
              videoCount: category.movies.length,
              order: category.order,
              display_type: category.display_type,
              actions: (
                <span>
                  <Link
                    to={"/category-details/" + category.id}
                    className="btn btn-info btn-sm"
                  >
                    <i className="fa fa-eye"></i>
                  </Link>{" "}
                  <Link
                    to={"/category-edit/" + category.id}
                    className="btn btn-warning btn-sm"
                  >
                    <i className="fa fa-edit"></i>
                  </Link>{" "}
                  <button
                    className="btn btn-danger btn-sm waves-effect waves-light"
                    onClick={() => this.handleConfirm(category.id)}
                  >
                    <i className="fa fa-trash"></i>
                  </button>
                </span>
              )
            };
          });
          let data = {
            columns: columns,
            rows: rows
          };
          this.setState({
            data: data
          });
          resolve(this.state.data);
        })
        .catch(() => {
          let errorMsg = "Erreur lors de la recuperation des categories.";
          reject(errorMsg);
        });
    });
  }
  handleDeleteCategory(categoryId) {
    this.props.deleteCategory({ categoryId }).then(() => {
      this.handleSuccess();
      this.props.getCategories();
    });
  }
  handleConfirm(categoryId) {
    const WarningText = () => (
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Oui"
        cancelBtnText="Non"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        title=""
        onCancel={() => this.hideAlert()}
        onConfirm={() => {
          this.setState({
            isLoading: true
          });
          this.handleDeleteCategory(categoryId)
            .then(() => {
              this.setState({
                isLoading: false
              });
            })
            .catch(() => {
              this.setState({
                isLoading: false
              });
            });
        }}
      >
        Êtes-vous sûre de vouloir supprimer cette catégorie ?
      </SweetAlert>
    );
    this.setState({ alert: WarningText() });
  }
  handleSuccess() {
    const SuccessText = () => (
      <SweetAlert
        success
        title=""
        onConfirm={() => {
          this.getCategoriesDatatable();
          this.hideAlert();
        }}
      >
        Catégorie supprimé avec succès !
      </SweetAlert>
    );
    this.setState({ alert: SuccessText() });
  }
  hideAlert() {
    this.setState({ alert: null });
  }
  render() {
    const { categories } = this.props;
    const { data, isLoading } = this.state;
    return (
      <AUX>
        {this.state.alert}
        <div className="row">
          <div className="col-sm-12">
            <div className="page-title-box">
              <h4 className="page-title">Catégories</h4>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="javascript:void(0);">Tableau de bord</Link>
                </li>
                <li className="breadcrumb-item active">Catégories</li>
              </ol>
              <div className="state-information d-none d-sm-block">
                <Link to="/add-category" className="btn btn-info">
                  Nouvelle catégorie
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card m-b-20">
              <div className="card-body">
                {isLoading || data.length === 0 ? (
                  <div className="alert alert-info text-center">
                    Chargement en cours ...
                  </div>
                ) : (
                  <MDBDataTable bordered hover data={data} />
                )}
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

const mapStateToProps = state => {
  return {
    categories: state.category.categories,
    isLoading: state.category.isLoading
  };
};

const mapDispatchToProps = {
  getCategories,
  deleteCategory
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesList);
