import { combineReducers } from "redux";
import Tinyreducer from "./TinychartReducer";
import UIreducer from "./uiReducer";
import errorReducer from "./errorReducer";
import authReducer from "./authReducer";
import categoryReducer from "./categoryReducer";
import movieReducer from "./movieReducer";
import userReducer from "./userReducer";

const rootReducer = combineReducers({
  ui_red: UIreducer,
  tiny_red: Tinyreducer,
  error: errorReducer,
  auth: authReducer,
  category: categoryReducer,
  movie: movieReducer,
  user: userReducer,
});

export default rootReducer;
