import axios from "axios";
import { returnErrors } from "./errorActions";

import {
  GET_MOVIES_LOADING,
  GET_MOVIES_SUCCESS,
  GET_MOVIES_FAIL,
  STORE_MOVIE_LOADING,
  STORE_MOVIE_SUCCESS,
  STORE_MOVIE_FAIL,
  GET_MOVIE_LOADING,
  GET_MOVIE_SUCCESS,
  GET_MOVIE_FAIL,
  UPDATE_MOVIE_LOADING,
  UPDATE_MOVIE_SUCCESS,
  UPDATE_MOVIE_FAIL,
  DELETE_MOVIE_LOADING,
  DELETE_MOVIE_SUCCESS,
  DELETE_MOVIE_FAIL,
  MOVIE_UPLOAD_LOADING,
  GET_MOVIE_PART_LOADING,
  GET_MOVIE_PART_SUCCESS,
  GET_MOVIE_PART_FAIL,
  STORE_MOVIE_PART_LOADING,
  STORE_MOVIE_PART_SUCCESS,
  STORE_MOVIE_PART_FAIL,
  UPDATE_MOVIE_PART_LOADING,
  UPDATE_MOVIE_PART_SUCCESS,
  UPDATE_MOVIE_PART_FAIL,
  DELETE_MOVIE_PART_LOADING,
  DELETE_MOVIE_PART_SUCCESS,
  DELETE_MOVIE_PART_FAIL,
  MOVIE_PART_UPLOAD_LOADING,
} from "./types";

export const getMovies = () => (dispatch, getState) => {
  dispatch({ type: GET_MOVIES_LOADING });
  return new Promise((resolve, reject) => {
    axios
      .get("https://itv-lm.paraclet-bk.com/api/movies", tokenConfig(getState))
      .then((response) => {
        resolve(dispatch({ type: GET_MOVIES_SUCCESS, payload: response.data }));
      })
      .catch((error) => {
        dispatch(returnErrors(error.response.data, error.response.status));
        dispatch({ type: GET_MOVIES_FAIL });
        reject(error.response.data);
      });
  });
};

export const storeMovie = (movie) => (dispatch, getState) => {
  dispatch({ type: STORE_MOVIE_LOADING });
  return new Promise((resolve, reject) => {
    axios
      .post("https://itv-lm.paraclet-bk.com/api/movies", movie, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + getState().auth.token,
        },
        onUploadProgress: (ProgressEvent) => {
          let percentage = Math.round(
            (ProgressEvent.loaded / ProgressEvent.total) * 100
          );
          dispatch({ type: MOVIE_UPLOAD_LOADING, payload: percentage });
        },
      })
      .then((response) => {
        resolve(
          dispatch({ type: STORE_MOVIE_SUCCESS, payload: response.data })
        );
      })
      .catch((error) => {
        console.log(error);
        // dispatch(returnErrors(error.response.data, error.response.status));
        dispatch({ type: STORE_MOVIE_FAIL });
        reject(error);
        // reject(error.response.data);
      });
  });
};

export const getMovie = ({ movieId }) => (dispatch, getState) => {
  dispatch({ type: GET_MOVIE_LOADING });
  return new Promise((resolve, reject) => {
    axios
      .get(
        "https://itv-lm.paraclet-bk.com/api/movies/" + movieId,
        tokenConfig(getState)
      )
      .then((response) => {
        console.log(response.data);
        resolve(dispatch({ type: GET_MOVIE_SUCCESS, payload: response.data }));
      })
      .catch((error) => {
        dispatch(
          error.response
            ? returnErrors(error.response.data, error.response.status)
            : returnErrors(error)
        );
        dispatch({ type: GET_MOVIE_FAIL });
        reject(error.response ? error.response.data : error);
      });
  });
};

export const updateMovie = (movie, movieId) => (dispatch, getState) => {
  dispatch({ type: UPDATE_MOVIE_LOADING });
  return new Promise((resolve, reject) => {
    axios
      .post(
        "https://itv-lm.paraclet-bk.com/api/update-movies/" + movieId,
        movie,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + getState().auth.token,
          },
          onUploadProgress: (ProgressEvent) => {
            let percentage = Math.round(
              (ProgressEvent.loaded / ProgressEvent.total) * 100
            );
            dispatch({ type: MOVIE_UPLOAD_LOADING, payload: percentage });
          },
        }
      )
      .then((response) => {
        resolve(
          dispatch({ type: UPDATE_MOVIE_SUCCESS, payload: response.data })
        );
      })
      .catch((error) => {
        dispatch(returnErrors(error.response.data, error.response.status));
        dispatch({ type: UPDATE_MOVIE_FAIL });
        reject(error.response.data);
      });
  });
};

export const deleteMovie = (movieId) => (dispatch, getState) => {
  dispatch({ type: DELETE_MOVIE_LOADING });
  return new Promise((resolve, reject) => {
    axios
      .delete(
        "https://itv-lm.paraclet-bk.com/api/movies/" + movieId,
        tokenConfig(getState)
      )
      .then((response) => {
        resolve(
          dispatch({ type: DELETE_MOVIE_SUCCESS, payload: response.data })
        );
      })
      .catch((error) => {
        dispatch(returnErrors(error.response.data, error.response.status));
        dispatch({ type: DELETE_MOVIE_FAIL });
        reject(error.response.data);
      });
  });
};

export const getMoviePart = ({ partId }) => (dispatch, getState) => {
  dispatch({ type: GET_MOVIE_PART_LOADING });
  return new Promise((resolve, reject) => {
    axios
      .get(
        "https://itv-lm.paraclet-bk.com/api/parts/" + partId,
        tokenConfig(getState)
      )
      .then((response) => {
        resolve(
          dispatch({ type: GET_MOVIE_PART_SUCCESS, payload: response.data })
        );
      })
      .catch((error) => {
        dispatch(
          error.response
            ? returnErrors(error.response.data, error.response.status)
            : returnErrors(error)
        );
        dispatch({ type: GET_MOVIE_PART_FAIL });
        reject(error.response ? error.response.data : error);
      });
  });
};

export const storeMoviePart = (part) => (dispatch, getState) => {
  dispatch({ type: STORE_MOVIE_PART_LOADING });
  return new Promise((resolve, reject) => {
    axios
      .post("https://itv-lm.paraclet-bk.com/api/parts", part, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + getState().auth.token,
        },
        onUploadProgress: (ProgressEvent) => {
          let percentage = Math.round(
            (ProgressEvent.loaded / ProgressEvent.total) * 100
          );
          dispatch({ type: MOVIE_PART_UPLOAD_LOADING, payload: percentage });
        },
      })
      .then((response) => {
        resolve(
          dispatch({ type: STORE_MOVIE_PART_SUCCESS, payload: response.data })
        );
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: STORE_MOVIE_PART_FAIL });
        reject(error);
      });
  });
};

export const updateMoviePart = (part, partId) => (dispatch, getState) => {
  dispatch({ type: UPDATE_MOVIE_PART_LOADING });
  return new Promise((resolve, reject) => {
    axios
      .post("https://itv-lm.paraclet-bk.com/api/update-parts/" + partId, part, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + getState().auth.token,
        },
        onUploadProgress: (ProgressEvent) => {
          let percentage = Math.round(
            (ProgressEvent.loaded / ProgressEvent.total) * 100
          );
          dispatch({ type: MOVIE_PART_UPLOAD_LOADING, payload: percentage });
        },
      })
      .then((response) => {
        resolve(
          dispatch({ type: UPDATE_MOVIE_PART_SUCCESS, payload: response.data })
        );
      })
      .catch((error) => {
        dispatch(returnErrors(error.response.data, error.response.status));
        dispatch({ type: UPDATE_MOVIE_PART_FAIL });
        reject(error.response.data);
      });
  });
};

export const deleteMoviePart = (partId) => (dispatch, getState) => {
  dispatch({ type: DELETE_MOVIE_PART_LOADING });
  return new Promise((resolve, reject) => {
    axios
      .delete(
        "https://itv-lm.paraclet-bk.com/api/parts/" + partId,
        tokenConfig(getState)
      )
      .then((response) => {
        resolve(
          dispatch({ type: DELETE_MOVIE_PART_SUCCESS, payload: response.data })
        );
      })
      .catch((error) => {
        dispatch(returnErrors(error.response.data, error.response.status));
        dispatch({ type: DELETE_MOVIE_PART_FAIL });
        reject(error.response.data);
      });
  });
};

export const tokenConfig = (getState) => {
  // Get token from localStorage
  const token = getState().auth.token;

  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  if (token) {
    config.headers["Authorization"] = "Bearer " + token;
  }

  return config;
};
