import axios from "axios";
import { returnErrors } from "./errorActions";

import { LOGINPAGE } from "./types";

export const updateLogin = () => dispatch => {
  dispatch({ type: LOGINPAGE, value: true });
};

export const updateLoginAgain = () => dispatch => {
  dispatch({ type: LOGINPAGE, value: false });
};
