import React, { Component } from "react";
import AUX from "../../hoc/Aux_";
import { connect } from "react-redux";
import { login } from "../../store/actions/authActions";
import { updateLogin, updateLoginAgain } from "../../store/actions/uiActions";
import { clearErrors } from "../../store/actions/errorActions";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
class Login extends Component {
  state = {
    email: "",
    password: "",
    msg: null
  };
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    error: PropTypes.object.isRequired,
    login: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired
  };
  componentDidMount() {
    if (this.props.isAuthenticated) {
      this.props.history.push("/");
    }
  }
  componentDidUpdate(prevProps) {
    const { error, isAuthenticated } = this.props;
    if (error !== prevProps.error) {
      console.log(error);
      // Check for login error
      if (error.id === "LOGIN_FAIL") {
        this.setState({ msg: error.msg.message });
      } else {
        this.setState({ msg: null });
      }
    }
    if (isAuthenticated) {
      this.props.history.push("/");
    }
  }
  componentWillUnmount() {
    this.props.clearErrors();
  }
  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onSubmit = e => {
    e.preventDefault();
    const { email, password } = this.state;

    // // Create user object
    const credentials = { email, password };

    // // Attempt to register
    this.props.login(credentials);
  };
  render() {
    return (
      <AUX>
        <div className="wrapper-page">
          <div className="card">
            <div className="card-body">
              <h3 className="text-center m-0">
                <Link to="/" className="logo logo-admin">
                  <img src="assets/images/logo.png" height="30" alt="logo" />
                </Link>
              </h3>
              <div className="p-3">
                <form
                  className="form-horizontal m-t-30"
                  onSubmit={this.onSubmit}
                >
                  {this.state.msg ? (
                    <div className="alert alert-danger" role="alert">
                      <strong>Oh snap!</strong> {this.state.msg}
                    </div>
                  ) : null}
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      placeholder=""
                      onChange={this.onChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="userpassword">Password</label>
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      placeholder=""
                      onChange={this.onChange}
                    />
                  </div>

                  <div className="form-group row m-t-20">
                    <div className="col-6 text-right">
                      <button
                        className="btn btn-primary btn-block w-md waves-effect waves-light"
                        type="submit"
                      >
                        CONNEXION
                      </button>
                    </div>
                  </div>

                  <div className="form-group m-t-10 mb-0 row">
                    <div className="col-12 m-t-20">
                      {!this.props.is_reg ? (
                        <button
                          onClick={this.props.RecoverPass}
                          className="text-muted btn btn-link"
                        >
                          <i className="mdi mdi-lock"></i> Forgot your password?
                        </button>
                      ) : (
                        <p className="font-14 text-muted mb-0">
                          By registering you agree to the Lexa{" "}
                          <button className="text-primary btn btn-link">
                            Terms of Use
                          </button>
                        </p>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="m-t-40 text-center">
            <p>
              Don't have an account ?{" "}
              <Link to="register" className="text-primary">
                {" "}
                Signup Now{" "}
              </Link>{" "}
            </p>
            <p>
              © {new Date().getFullYear()} Lexa. Crafted with{" "}
              <i className="mdi mdi-heart text-danger"></i> by Themesbrand
            </p>
          </div>
        </div>
      </AUX>
    );
  }
}

const mapStatetoProps = state => {
  return {
    loginpage: state.ui_red.loginpage,
    isAuthenticated: state.auth.isAuthenticated,
    error: state.error
  };
};

const mapDispatchtoProps = {
  updateLogin,
  updateLoginAgain,
  login,
  clearErrors
};

export default connect(mapStatetoProps, mapDispatchtoProps)(Login);
